.paymentCardsParentDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 670px;
    border-radius: 20px;
    background-color: black;
  }

  .paymentSectionSmallHeader {
    position: absolute;
    font-size: 1.5rem;
    color: white;
    margin: 0px;
    top: 20px;
    left: 25px;
    font-weight: 400;
  }

  .addPaymentCardButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    background-color: white;
    border: 3px solid black;
    border-radius: 10px;
    font-size: 2rem;
    z-index: 5;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

.addPaymentCardButton:hover {
  color: white;
  background-color: #2dc653;
}

.addPaymentCardButtonPopup {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 130px;
    height: 30px;
    background-color: #e5e5e5;
    top: -25px;
    right: -57px;
  }
  
  .addPaymentCardButtonPopup::after {
    content: "";
    position: absolute;
    bottom: -10px; /* Adjust based on your needs */
    left: 20%; /* Center the triangle */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e5e5e5; /* Same color as the popup background */
    transform: translateX(-50%);
  }

  @media (max-width: 715px) {

    .paymentCardsParentDiv {

      border-radius: 0px;

    }

    .addPaymentCardButton {
      right: 45px;
    }

  }

  @media (max-width: 450px) {

  .paymentCardsParentDiv {
    background-color: black;
  }

  .paymentSectionSmallHeader {
    color: white;
  }

  }

