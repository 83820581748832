.buyWatchFormSection2 {
    display: flex;
    flex-direction: column;
    border: none;
    padding: 20px;
    width: 852px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    transition: 0.3s background-color ease, 0.3s height ease;
}

.buyWatchFormSectionAnimatedDiv {
    width: 100%;
    height: 100%;
    animation: formSideSlide 0.5s ease-out forwards;
}  



.buyWatchFormStandardDiv {
    display: flex;
    width: 100%;
}

.formShippingRadioText {
    font-size: 1.2rem;
    margin-left: 5px;
    transition: color 0.3s ease;
    cursor: pointer;

}

.shippingRadioButtonsParent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 55px;
    margin-top: 20px;

}

.buyWatchFormInputDiv {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
}

.buywatchFormLabelDiv {
    display: flex;
    justify-content: flex-end;
    width: 20%;
}

.buyWatchFormInputLabel {
    font-size: 1.2rem;
    font-weight: 500;
    transition: 0.3s color ease;
    cursor: pointer;
}

.buyWatchFormTextInput {
    font-family: 'Roboto';
    font-size: 1.1rem;
    height: 44px;
    margin-left: 27px;
    padding: 2px;
    padding-left: 7px;
    border: 1px solid #ced4da;
    border-radius: 7px;
    transition: border-color 0.3s ease;
    width: 78%;
}

.buywatchFormDropdown {
    background-color: red;
    width: 100%;
}

.buyWatchFormTextInput:focus {
    outline: none;
    border: 1px solid black;
}

@keyframes formSideSlide {
    from {
      transform: translateX(15px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 1410px) {

    .buyWatchFormSection2 {
        width: 775px;

    }

  }

  @media (max-width: 1300px) {

    .buyWatchFormSection2 {
        width: 700px;

    }

    .buywatchFormLabelDiv {
        width: 21%;
    }

  }

  @media (max-width: 1200px) {

    .buyWatchFormSection2 {
        width: 620px;
        padding-left: 10px;

    }

    .buywatchFormLabelDiv {
        width: 25%;
    }

  }

  @media (max-width: 1080px) {

    .buyWatchFormSection2 {
        width: 852px;
        padding-left: 20px;

    }

    .buywatchFormLabelDiv {
        width: 20%;
    }

  }

  @media (max-width: 960px) {

    .buyWatchFormSection2 {
        width: 775px;

    }

  }

  @media (max-width: 870px) {

    .buyWatchFormSection2 {
        width: 700px;

    }

    .buywatchFormLabelDiv {
        width: 21%;
    }

  }

  @media (max-width: 780px) {

    .buyWatchFormSection2 {
        width: 620px;
        padding-left: 10px;

    }

    .buywatchFormLabelDiv {
        width: 25%;
    }

  }

  @media (max-width: 700px) {

    .buyWatchFormSection2 {
        align-items: center;
        width: 520px;
        padding: 20px;

    }

    .buywatchFormLabelDiv {
        width: 29%;
    }

    .buyWatchFormInputLabel {
        font-size: 1.1rem;
    }

  }

  @media (max-width: 570px) {

    .buyWatchFormSection2 {
        align-items: center;
        width: 345px;
        padding: 20px;
        color: green;
        margin-top: 100px;
    }


    .buyWatchFormInputLabel {
        font-size: 1.1rem;
    }

    .buyWatchFormInputDiv {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 70px;

    }

    .buywatchFormLabelDiv {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .buyWatchFormTextInput {
        font-family: 'Roboto';
        font-size: 1.1rem;
        height: 44px;
        margin-left: 0px;
        margin-top: 4px;
        width: 96%;
    }

  }