.LargeListingStatisticsParentDiv {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 720px;
    padding: 15px;
    box-sizing: border-box;
    background: linear-gradient(to bottom right, var(--silver-1), var(--silver-3));
    border-radius: 8px;

}

.smallListingAndKeyStatsParentDiv {
    display: flex;
    width: 470px;
    height: 340px;
}



.lisingVisitorsChartParentDiv {
    display: flex;
    padding-left: 10px;
    background-color: black;
    margin-top: 15px;
    width: 470px;
    height: 200px;
    border-radius: 8px;
}

.visitorChartKeyStatsDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 200px;
    width: 60px;
    box-sizing: border-box;
    margin-left: 15px;
    border-radius: 8px;

}

.largeListingStatisticsActionsSectionsParent {
    display: flex;
    width: 470px;
    height: 130px;
}

.largeListingStatisticsActionsSection {
    display: flex;
    flex-direction: column;
    width: 227px;
    height: 120px;
    margin-top: 15px;

    box-sizing: border-box;
    padding: 8px;
    border-radius: 8px;

}


.listingStatisticsUpgradeListingDiv {
    display: flex;
    flex-direction: column;
    width: 190px;
    height: 70px;
    background-color: var(--blue-2);
}
