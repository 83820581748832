.unlockButtonParentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    background-color: white;
    border: none;
    border-radius: 8px;
    z-index: 101;
    cursor: pointer;
    transition: all 0.3s ease;


}

.parentIsHovered {
    background-color: var(--blue-3);
}

.buttonIsActive {
    background-color: #2dc653;
    transition: all 0.3s ease;

}
.unlockButtonParentDiv:hover {
    background-color: var(--blue-1);

  }

  .unlockButtonParentDiv.buttonIsActive:hover {
    background-color: #25a244;
  }


  .unlockButtonText {
    color: black;
}

.unlockButtonParentDiv:hover .unlockButtonText {
    animation: none;
    color: white;
  }

  @media (max-width: 900px) {

    .unlockButtonParentDiv {
      width: 80px;
      height: 40px;
  }

  }

 
  @media (max-width: 500px) {

    .unlockButtonParentDiv {
      width: 60px;
      height: 40px;
  }

  }
