.purchasedWatchThumbnailParent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 210px;
    height: 306px;
    padding: 10px;
    padding-bottom: 0px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: var(--silver-2);
}

.sliderStyles {
    width: 190px;
    height: 190px;
    border-radius: 8px;
    overflow: hidden; /* This is important to ensure the inner content also conforms to the border radius */

}

.purchasedWatchImage {
    width: 190px;
    height: 190px;
    border-radius: 8px;
}

.purchasedWatchtext {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;

}

.purchasedWatchTextsParent {
    display: flex;
    flex-direction: column;
    width: 190px;
    height: 90px;
    margin-top: 8px;
    margin-left: 4px;
}

.purchasedWatchBrandName {
    font-size: 0.9rem;
    font-weight: 400;
}

.purchasedWatchModelName {
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 4px;
}

.purchasedWatchPrice {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
}

.purchasedWatchDate {
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 11px;
    align-self: center;
}