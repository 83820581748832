.customPrevArrowStyles {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 32%;
    background-color: var(--silver-2);
    cursor: pointer;
    width: 50px;
    height: 50px;
    z-index: 10;
    border-radius: 4px;
    transition: background-color 0.3s ease, opacity 0.3s ease, border 0.1s ease;

}

.customPrevArrowStyles:hover {
    background-color: var(--silver-1);
    border: 1px solid var(--gold-2)
}