.threeArrowParentDiv {
    display: flex;
    width: 20px;
    height: 20px;
    overflow: hidden;
    position: relative;
}

.threeArrowParentDiv > * {
    position: absolute;
    transition: left 0.3s ease;
}

.no-transition {
    transition: none !important;
}