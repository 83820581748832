.listingStatisticsDiv {
    position: relative;
    display: flex;
    flex-direction: column;

    padding: 15px;
    box-sizing: border-box;    
    border-radius: 8px;
    background: linear-gradient(to bottom right, var(--silver-1), var(--silver-3));
    transition: transform 0.4s cubic-bezier(0.15, 1, 0.35, 1);
    transform-origin: center; /* This ensures the scaling happens from the center */

}



  .listingStatisticsDiv.large {
    /* Your styles here */
    display: flex;
    align-items: center;
    justify-content: center;

}

.listingStatisticsDivEnlargebutton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    top: -10px;
    right: -10px;
    border: none;
    z-index: 10;
    cursor: pointer;
    border-radius: 20px;
    background-color: var(--silver-2);
    transition: background-color 0.2s ease;
}

.listingStatisticsDivEnlargebutton:hover {
    background-color: var(--silver-1);
}

.listingStatisticsStat {
    position: relative;
    display: flex;
    align-items: center;
    width: 240px;
    height: 40px;
    margin-left: 5px;
}

.listingStatisticsStatHeader {
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0px;
    margin-top: 0px;
    margin-left: 7px;
    color: white;

}

.listingStatisticsStatText {
    font-size: 1rem;
    font-weight: 400;
    margin: 0px;
    margin-top: 2px;
    margin-left: 7px;
    color: white;
}

.listingStatisticInfoButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0px;
}

.listingStatisticPopupDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: rgba(0, 0, 0, 0.8); /* Black background with some transparency */
    color: white; /* White text color */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 150px; /* Control the width */
    top: 0px;
    right: -160px; /* Position it at the right side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: justify; /* Justify the text */
}

.listingStatisticPopupDiv:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;  /* Positioned to the left of the div */
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: rgba(0, 0, 0, 0.8);  /* Make the triangle */
    transform: translateY(-50%);  /* Center the triangle vertically */
  }
  