@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.toggleOption {
    position: relative;
    font-family: 'Roboto' sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin: 0 30px;
    cursor: pointer;    
}





.toggleOption::after {
    content: '';
    position: absolute;
    bottom: -7px; 
    left: 50%; 
    width: 0%; /* initially the width is 0 */
    border-bottom: 2px solid black;
    transition: width 0.3s, left 0.3s; /* this will animate the width and left */
}

.toggleOption.leftActive::after, .toggleOption.rightActive::after {
    width: 60%;
    left: 20%;
}
