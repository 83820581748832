.profileHomeContentDiv {
    position: relative;
    display: flex;
    width: 100%;

}

.profileHomeSidebarDiv {
    width: 250px;
    height: 100%;
}

.profileHomeMainContentDiv {
    display: flex;
    flex-direction: column;
    width: 90%;

    padding: 25px;
    box-sizing: border-box;
}

.profileHomeMainContentHeaderParent {
    display: flex;
    height: 42px;
}

.profileHomeMainContentHeader {
    margin-top: 15px;
    width: 400px;
}

.headerUnderlineDiv {
    transition: width 0.5s ease;
    height: 1px;
    background-color: black;
    margin-bottom: 10px;
}

.no-transition {
    transition: none !important;
  }

  @media only screen and (max-width: 1000px) {

    .profileHomeMainContentDiv {
        width: 100%;
    }

    .profileHomeMainContentHeaderParent {
        margin-left: 50px;
    }

    .headerUnderlineDiv {
        margin-left: 50px;
    }
  }

@media only screen and (max-width: 800px) {

  .profileHomeMainContentHeaderParent {
    margin-top: 0px;
}

}

  @media only screen and (max-width: 650px) {
    .profileHomeContentDiv {
        flex-direction: column;
        
    }

    .profileHomeSidebarDiv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        width: 60px;
    }

  }
  
  @media only screen and (max-width: 500px) {


    .profileHomeMainContentHeaderParent {
        margin-left: 30px;
    }

    .headerUnderlineDiv {
        margin-left: 30px;
    }

  }