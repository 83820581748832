.visitorsStatButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55px;
    height: 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: padding 0.3s ease;
}






.largeListingkeyStatPopupDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: white; /* Black background with some transparency */
    color: black; /* White text color */
    padding: 7px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 270px; /* Control the width */
    height: 40px;
    top: -70px;
    left: -235px; /* Position it at the right side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.35; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: start; /* Justify the text */
    
}

.largeListingkeyStatPopupDiv::after {
    content: ''; /* Empty content */
    position: absolute; /* Absolute positioning */
    bottom: -8px; /* Position from the bottom of the parent div */
    right: 10px; /* Align to the right of the parent div */
    width: 0; /* Zero width */
    height: 0; /* Zero height */
    border-left: 10px solid transparent; /* Create left half of the arrow */
    border-right: 10px solid transparent; /* Create right half of the arrow */
    border-top: 10px solid white; /* Create the top of the arrow and set its color */

  }