.SellWatchInputParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-image: url('../assets/SellWatchPageImages/coolBackgroundImage4.png'); /* Replace with the path to your image */
    background-size: cover; /* This ensures the image covers the entire div */
    background-repeat: no-repeat; /* Prevents image from repeating */
    background-position: center; /* Centers the image in the div */}

.sellYourWatchInputComponent {
    display: flex;


}

.sellYourWatchInputComponentFirstItem {
    display: flex;

}

.sellYourWatchInputComponentFirstItem2ndClass {
    width: 30%;
    height: 100%;
}

.sellYourWatchInputComponentSecondItem {

}

.sellWatchDropdownOrTextParentDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sellYourWatchPriceInput2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;

    border-radius: 10px;
    border: 2px solid white;
    background-color: #d81939;
}

@media only screen and (max-width: 570px) {

    .SellWatchInputParentDiv {

    }

    .sellYourWatchInputComponent {
        flex-direction: column;
    }

    .sellYourWatchInputComponentFirstItem2ndClass {
        width: 100%;
    }
    
}