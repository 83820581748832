.thumb-widget-link {
    display: flex;
    width: 66%;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    text-decoration: none; /* Optional: To remove the underline from the link */
}


.thumb-widget-container {


    position: relative;
    width: 100%;
    height: 100%;
    background-color: #00a6fb;
    border-radius: 8px;
    transition: transform 0.2s, margin-left 0.2s, background-color 0.4s ease-in-out;
    /* You might want to add some padding here to give some space inside the container */
    padding: 12px;


}

.image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* If you want to give some spacing between the image and the text, you can use margin-bottom */
    margin-bottom: 10px;
    overflow: hidden; /* Add this to clip the scaled-up image */
    border-radius: 10px; /* You can adjust this value */


}

.image-container img {
    /* If you want to set a specific height for the image */
    height: auto;
    /* Or a specific width */
    width: 100%; /* For example, 90% of its container */
    border-radius: 10px; /* You can adjust this value */
    transform: scale(1); /* Start at normal size */
    transition: transform 0.5s ease-in-out; /* Transition effect */



}

.thumb-widget-container:hover {
    transform: scale(1.05);
    background-color: #00296b;

}

.thumb-widget-container:hover .image-container img {
    /* Final state on container hover */
    transform: scale(1.05); /* Scale the image up */
}


.thumb-widget-container:hover .divStyle {
    margin-top: 0px;
}

.thumb-widget-container .spanStyle {
    position: relative;

}

.thumb-widget-container:hover .spanStyle {
    color: white;
}

.thumb-widget-container .spanStyle::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: white;
    transition: width 0.4s;
}

.thumb-widget-container:hover .spanStyle::after {
    width: 100%;
}

@media (max-width: 1180px) {

    .thumb-widget-link {
        width: 75%;
    }

}


@media (max-width: 800px) {

    .thumb-widget-link {
        width: 86%;
    }

}

@media (max-width: 620px) {

    .thumb-widget-link {
        width: 90%;
    }

}