.paymentInformationInputSpace {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 475px;
    padding-left: 10px;
    box-sizing: border-box;
}

.paymentInformationSmallText {
    font-size: 0.95rem;
    font-weight: 400;
    color: black;
    margin: 0px;
    margin-top: 20px;

}

.paymentInformationSmallTextInputField{
    width: 160px;
    height: 40px;
    font-size: 1.1rem;
    margin-left: 20px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
}

.paymentInformationSmallTextInputField:focus {
    outline: none; /* Remove the default outline */
    background-color: var(--silver-1);
  }

  @media (max-width: 900px) {

    .paymentInformationInputSpace {
        width: 100%;
    }

  }