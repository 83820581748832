.SellPageHeaderSectionResetLinkStyles {
    text-decoration: none;
    color: inherit;
    display: block;  /* or inline-block, depending on your needs */
    width: 25%;
    height: 100%;

}

.sellPageHeaderDivSectionDiv {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.sellPageHeaderDivSectionDivNotActivated {
    display: flex;
    width: 25%;
    height: 100%;
    box-sizing: border-box;
}

.sellPageHeaderDivSectionRectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s ease-in-out;

}


.sellPageHeaderDivSectionTriangle {
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    transition: border-left 0.3s ease-in-out;
  }

  .headerSectionLastDiv {
    transition: background-color 0.3s ease-in-out;;
  }

  .sellPageHeaderSectionText {
    font-size: 1.3rem;
  }

