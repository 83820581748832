
  .purchasedWatchesSliderDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 955px;
    height: 400px;
    padding: 15px;
    box-sizing: border-box;
    background-color: var(--blue-3);
    margin-top: 40px;
    border-radius: 20px;
  }

  .purchasedWatchesHeader {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    color: white;
    margin-left: 10px;
    margin: 0px;
    margin-left: 22px;
    font-weight: 400;
  }

  .purchasedWatchesOnlySliderDiv {
    display: flex;
    align-items: center;
    align-self: center;
    width: 900px;
    height: 312px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 1200px) {

    .purchasedWatchesSliderDiv {
      width: 700px;
    }

    .purchasedWatchesOnlySliderDiv {
      width: 680px;
    }

  }

  @media only screen and (max-width: 772px) {

    .purchasedWatchesSliderDiv {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    .purchasedWatchesOnlySliderDiv {
      width: 460px;
    }

  }

  @media only screen and (max-width: 550px) {

    .purchasedWatchesSliderDiv {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }

    .purchasedWatchesOnlySliderDiv {
      width: 230px;
    }

    .purchasedWatchesHeader {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.32rem;

      margin-left: 10px;

    }

  }