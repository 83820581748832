.watchPicturesPageParentDiv {
    display: flex;
    width: 100%;
    min-height: 1000px;
    background-color: white;
}

.watchPicturesPageInputParentDiv {
    display: flex;
    width: 100%;
    min-height: 1000px;
    padding: 40px;
    box-sizing: border-box;
    flex-direction: column;
}

.watchPicturesPageAddPicturesSectionParentDiv {
    display: flex;
    flex-direction: column;
    width: 820px;
    box-sizing: border-box;

    padding: 20px;
    border: 3px solid black;
    border-radius: 10px;
    background: linear-gradient(to bottom right, var(--silver-3), var(--silver-1));
}

.watchPicturesSecuritySectionParentDiv {
    display: flex;
    flex-direction: column;
    width: 820px;
    margin-top: 40px;
    box-sizing: border-box;
    height: 500px;
    background: linear-gradient(to bottom right, var(--silver-3), var(--silver-1));
    padding: 20px;
    border-radius: 10px;
    border: 3px solid black;
}


.watchSecurityPicturesParentDiv {
    display: flex;
    width: 780px;
    height: 300px;
    margin-top: 30px;
    border: 3px solid black;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #d9d9d6;
}

.watchSecurityPicturesChildDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;

}

.no-underline {
    text-decoration: none; /* Removes the underline */
  }


@media only screen and (max-width: 1300px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 770px;
    }
  
    .watchPicturesSecuritySectionParentDiv {
        width: 770px;
        height: 450px;
    }

    .watchSecurityPicturesParentDiv {
        width: 720px;
        height: 255px;

    }

}

@media only screen and (max-width: 1240px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 700px;
    }
  
    .watchPicturesSecuritySectionParentDiv {
        width: 700px;
        height: 440px;
    }

    .watchSecurityPicturesParentDiv {
        width: 630px;
        height: 228px;

    }

}

@media only screen and (max-width: 1120px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 630px;
    }
  
    .watchPicturesSecuritySectionParentDiv {
        width: 630px;
    }

    .watchSecurityPicturesParentDiv {
        width: 590px;
        height: 228px;

    }

}

@media only screen and (max-width: 1032px) {

    .watchPicturesPageInputParentDiv {
        align-items: center;
        padding: 0px;
        padding-top: 40px;
    }

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 820px;
    }
  
    .watchPicturesSecuritySectionParentDiv {
        width: 820px;
        height: 500px;
    }

    .watchSecurityPicturesParentDiv {
        width: 780px;
        height: 300px;

    }

}

@media only screen and (max-width: 900px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 745px;
    }
  
    .watchPicturesSecuritySectionParentDiv {
        width: 745px;
        height: 450px;
    }

    .watchSecurityPicturesParentDiv {
        width: 700px;
        height: 255px;

    }
}

@media only screen and (max-width: 820px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 642px;
    }

    .watchPicturesSecuritySectionParentDiv {
        width: 642px;
        height: 450px;
    }

    .watchSecurityPicturesParentDiv {
        width: 597px;
        height: 255px;

    }

}
@media only screen and (max-width: 700px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 500px;
    }
  
    .watchPicturesSecuritySectionParentDiv {
        width: 500px;
        height: 450px;
    }

    .watchSecurityPicturesParentDiv {
        width: 460px;
        height: 255px;

    }

}

@media only screen and (max-width: 570px) {

    .watchPicturesPageAddPicturesSectionParentDiv {
        width: 336px;
        padding: 10px;
    }

    .watchPicturesSecuritySectionParentDiv {
        width: 336px;
        height: 625px;
    }

    .watchSecurityPicturesParentDiv {
        flex-direction: column;
        width: 290px;
        height: 420px;
        background: none;
        border: none;
        margin-top: 10px;
    }

    .watchSecurityPicturesChildDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 195px;
        box-sizing: border-box;
        padding: 10px;
    
    }

}