.thanksForPurchaseParent {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    padding: 40px;
    box-sizing: border-box;
    animation: fadeInTextUpwards 0.5s ease forwards; /* Adjust the timing as you like */

}

.thanksForPurchaseHeader {
}

@keyframes fadeInPageUpwards {
    from {
      opacity: 0;
      transform: translateY(10px); /* Start from 10px below */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.thanksForPurchaseInfo {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 900px;
    height: 560px;
    margin-top: 40px;

    box-sizing: border-box;
    border-radius: 12px;
    background-color: var(--gold-1);
}

.thanksForPurchaseTextDiv {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 530px;
    height: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    box-sizing: border-box;
}

.thanksForPurchaseText {
    font-size: 1.1rem;
    font-weight: 500;
}

@media only screen and (max-width: 1032px) {

  .thanksForPurchaseInfo {
    width: 95%;
}



}

@media only screen and (max-width: 800px) {

  .thanksForPurchaseParent {
    padding: 0px;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .thanksForPurchaseInfo {
    flex-direction: column;
    width: 400px;
    height: 1000px;
}

.thanksForPurchaseTextDiv {
  width: 100%;
}

}

@media only screen and (max-width: 440px) {

  .thanksForPurchaseInfo {
    width: 350px;
    height: 500px;
}

.thanksForPurchaseTextDiv {
  width: 100%;
}

}