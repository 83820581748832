.cardContainer {
    position: relative;
    width: 710px;
    height: 570px;
    z-index: 2;
  }
  
  .card {
    position: absolute;


    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    color: white;
    border: 1px solid black;
  }
  
  .card0 {

    z-index: 4;
    top: 0px;
    left: 0px;

  }
  
  .card1 {

    z-index: 3;
    top: -20px;
    left: -20px;
  }
  
  .card2 {

    z-index: 2;
    top: -35px;
    left: -35px;
  }
  
  /* Add more for card3, card4, etc., up to the maximum number you want to support */
  .card3 {

    z-index: 1;
    top: -45px;
    left: -45px;
  }
  
  .card4 {

    z-index: 0;
    top: -50px;
    left: -50px;
  }
  
  .cardSwitchButton {
    position: absolute;
    background-color: white;
    z-index: 6;
    right: 20px;
    top: 20px;
    width: 130px;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 550;
    color: black;
    transition: all 0.3s ease;
  }
  
  .cardSwitchButton:hover {
    background-color: var(--blue-1);
    color: white;
  }

  .deleteCardButton {
    position: absolute;
    background-color: white;
    z-index: 6;
    right: 20px;
    top: 80px;
    width: 130px;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    font-weight: 550;
    color: black;
    transition: all 0.3s ease;
  }

  .deleteCardButton:hover {
    background-color: #ef233c;
    color: white;
  }

  @media (max-width: 900px) {

    .cardContainer {
      position: relative;
      width: 510px;
      height: 570px;
      z-index: 2;
    }

    .cardSwitchButton {
      right: 90px;
      top: 20px;
      width: 90px;
      height: 40px;
      font-size: 1rem;
      font-weight: 550;

    }
    
    .cardSwitchButton:hover {
      background-color: var(--blue-1);
      color: white;
    }
  
    .deleteCardButton {
      right: -30px;
      top: 20px;
      width: 105px;
      height: 40px;
      font-size: 1rem;
      font-weight: 550;

    }

  }

  @media (max-width: 640px) {

    .cardContainer {
      position: relative;
      width: 450px;
      height: 570px;
      z-index: 2;
    }

    .cardSwitchButton {
      right: 70px;
      top: 26px;
      width: 40px;
      height: 40px;
      font-size: 1rem;
      font-weight: 550;

    }
    
    .cardSwitchButton:hover {
      background-color: var(--blue-1);
      color: white;
    }
  
    .deleteCardButton {
      right: 20px;
      top: 26px;
      width: 40px;
      height: 40px;
      font-size: 1rem;
      font-weight: 550;

    }

  }

  @media (max-width: 500px) {

    .cardContainer {
      position: relative;
      width: 368px;
      height: 570px;
      z-index: 2;
    }

    .cardSwitchButton {
      right: 68px;
      top: 26px;
      width: 40px;
      height: 40px;
      font-size: 1rem;
      font-weight: 550;

    }
    
    .cardSwitchButton:hover {
      background-color: var(--blue-1);
      color: white;
    }
  
    .deleteCardButton {
      right: 16px;
      top: 26px;
      width: 40px;
      height: 40px;
      font-size: 1rem;
      font-weight: 550;

    }

  }


  @media (max-width: 450px) {

    .card0 {

      z-index: 4;
      top: 0px;
      left: 0px;
  
    }
    
    .card1 {
  
      z-index: 3;
      top: -14px;
      left: -5px;
    }
    
    .card2 {
  
      z-index: 2;
      top: -22px;
      left: -10px;
    }
    
    /* Add more for card3, card4, etc., up to the maximum number you want to support */
    .card3 {
  
      z-index: 1;
      top: -28px;
      left: -15px;
    }
    
    .card4 {
  
      z-index: 0;
      top: -33px;
      left: -20px;
    }

  }