.smallWatchListingParent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 340px;
    box-sizing: border-box;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
}

.smallWatchListingImage {
    width: 210px;
    height: 210px;
    border-radius: 6px;
}

.smallWatchListingHeader {
    font-size: 0.95rem;
    font-weight: 500;
    margin: 0px;
    margin-top: 2px;
    margin-left: 5px;
}

.smallWatchListingCommentDiv {
    display: flex;
    align-items: center;
    width: 210px;
    height: 30px;
    margin-top: 3px;
    border-radius: 4px;
    background-color: #e9ecef;
}

.smallWatchListingComment {
    font-size: 0.9rem;
    font-weight: 400;
    padding-left: 5px;
}

.showFullSellerCommentButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 4px;
    background-color: black;
    border-radius: 3px;
    border: none;
}

.sellerCommentPopupDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: rgba(0, 0, 0, 0.8); /* Black background with some transparency */
    color: white; /* White text color */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 190px; /* Control the width */
    bottom: 0;
    right: 0px; /* Position it at the right side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: justify; /* Justify the text */
}

.smallWatchListingPriceAndLocationDiv {
    position: relative;
    display: flex;
    width: 210px;
    height: 40px;
}

.smallWatchListingPriceAndShippingDiv {
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 40px;

}



.smallWatchListingPrice {
    font-size: 1rem;
    font-weight: 400;
    margin: 0px;
    margin-top: 6px;
    margin-left: 5px;
}

.smallWatchListingShipping {
    font-size: 0.65rem;
    font-weight: 400;
    margin: 0px;
    margin-top: 5px;
    margin-left: 5px;
    color: #6c757d;
}

.smallWatchListingLocationButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25px;
    height: 30px;
    margin-top: 10px;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 86px;
}

.sellerLocationPopupDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: rgba(0, 0, 0, 0.8); /* Black background with some transparency */
    color: white; /* White text color */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 190px; /* Control the width */
    top: -60px;
    right: -15px; /* Position it at the right side */
    width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: justify; /* Justify the text */
}