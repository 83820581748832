.listingInfoHeaderAndContentParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 2000px;
    padding-bottom: 40px;
    box-sizing: border-box;
    background-color: #0a2463;

}

.detailedWatchInfoParentDiv {
    display: flex;
    justify-content: space-around;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    min-height: 2000px;

}

.detailedWatchInfoBasicInfoParentDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 2000px;
    width: 48%;
    padding: 10px;
    padding-bottom: 15px;
    background: linear-gradient(to top left, #0077b6, #0a2463);
    border-radius: 12px;
}

.detailedWatchInfoSellerAndShippingAndDescriptionParentDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 800px;
    width: 50%;
    padding: 10px;
    padding-bottom: 15px;
    background: linear-gradient(to bottom right, #0077b6, #0a2463);
    border-radius: 12px;
}

.descriptionComponent {
    position: sticky;
    top: 10px;
}

@media only screen and (max-width: 960px) {

    .detailedWatchInfoParentDiv {
        flex-direction: column;
        align-items: center;
    }

    .detailedWatchInfoBasicInfoParentDiv {
        width: 90%;
    }

    .detailedWatchInfoSellerAndShippingAndDescriptionParentDiv {
        width: 90%;
        margin-top: 50px;
    }

}

@media only screen and (max-width: 550px) {

    .detailedWatchInfoBasicInfoParentDiv {
        width: 95%;
    }

    .detailedWatchInfoSellerAndShippingAndDescriptionParentDiv {
        width: 95%;
    }
}
