.personalInformationComponentParentDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 800px;
    min-height: 500px;
    padding: 20px;
    padding-left: 30px;
    box-sizing: border-box;
    border-radius: 12px;


}

.personalInformationComponentLockCoverDiv {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    opacity: 0;
    background-color: #6c757d;
    z-index: 100;
    transition: opacity 0.5s ease;
    cursor: not-allowed;

}

.personalInformationComponentLockCoverDiv:hover {
    opacity: 0.2;
}

.personalInformationHeaderAndUnlockButtonDiv {
    display: flex;
    justify-content: space-between;
    height: 50px;
    width: 510px;
}

@keyframes unlockButtonColorShift {
    0% {
      color: black;
    }
    50% {
      color: white;
    }
    100% {
      color: black;
    }
  }

.personalInformationHeaderDiv {
    font-size: 1.4rem;
    font-weight: 400;
    color: black;
    margin: 0px;
}



  .personalInformationComponentParentDiv:hover .unlockButtonText {
    animation: unlockButtonColorShift 1.5s infinite;
  }



.personalInformationInputSpace {
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 360px;
    padding-left: 10px;
    box-sizing: border-box;
}

.personalInformationSmallText {
    font-size: 0.95rem;
    font-weight: 400;
    color: black;
    margin: 0px;
    margin-top: 20px;

}

.personalInformationSmallTextInputField{
    width: 160px;
    height: 40px;
    font-size: 1.1rem;
    margin-left: 20px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
}

.personalInformationSmallTextInputField:focus {
    outline: none; /* Remove the default outline */
    background-color: var(--silver-1);
  }

  @media (max-width: 640px) {

    .personalInformationComponentParentDiv {
      width: 800px;
      min-height: 500px;
      padding: 10px;
      padding-top: 25px;
      padding-left: 20px;

  
  
  }

  }

  @media (max-width: 500px) {

    .personalInformationHeaderDiv {
      font-size: 1.32rem;
      font-weight: 400;
      color: black;
      margin: 0px;
  }

  }

