.recommendedSellerListingsParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 550px;
    margin-top: 50px;
    background-color: var(--silver-1);
    box-sizing: border-box;
    padding-top: 10px;
    border-radius: 10px;
}

.sellerProfileRecommendedListingsDiv {
    position: relative;
    display: flex;
    width: 100%;
    height: 440px;
    margin-top: 10px;
}

.recommendedListingsHeader {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 10px;
    margin-left: 12px;
    margin-bottom: 10px;
}

.customPrevArrowStyles {
    width: 50px;
    height: 50px;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }


@media only screen and (max-width: 1425px) {

    .recommendedSellerListingsParentDiv {
        height: 550px;
    }

    .recommendedListingsHeader {
        margin-left: 20px;
    }

    .sellerProfileRecommendedListingsDiv {
        justify-content: space-evenly;
    }


}


@media only screen and (max-width: 1125px) {

    .recommendedSellerListingsParentDiv {
        height: 550px;
        width: 770px;

    }

    .sellerProfileRecommendedListingsDiv {
        height: 440px;

    }

    .slick-slide {
        display: flex !important;
        justify-content: space-evenly;
        align-items: center;
      }
}

@media only screen and (max-width: 950px) {
    .recommendedSellerListingsParentDiv {
        width: 670px;
    }
}


@media only screen and (max-width: 850px) {

    .recommendedListingsHeader {
        font-size: 1.28rem;
        margin-top: 5px;
    }

    .recommendedSellerListingsParentDiv {
        width: 450px;
        height: 550px;
    }

    .sellerProfileRecommendedListingsDiv {
        height: 440px;
        margin-top: 10px;
    }

}

@media only screen and (max-width: 650px) {


    .recommendedSellerListingsParentDiv {
        width: 360px;
        height: 510px;

    }
    
    .recommendedListingsHeader {
        font-size: 1rem;
        margin-top: 5px;
    }

    .slick-dots {
        display: none !important;
      }
}

@media only screen and (max-width: 390px) {

    .recommendedSellerListingsParentDiv {
        width: 100%;
    }

}