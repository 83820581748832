.buyWatchPageParent {
    display: flex;
    width: 100%;
    min-height: 262vh;
    padding-bottom: 40px;
    box-sizing: border-box;
}

.buypageHeader {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0px;
}

.buyWatchPageFormSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    padding-top: 30px;
    box-sizing: border-box;
}


.buyWatchFormParent {
    display: flex;
    flex-direction: column;
    width: 900px;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #a9d6e6;
    border-radius: 12px;
}

.buyWatchFormHeader {
    font-size: 1.8rem;
    font-weight: 400;
}




.buyWatchFormActualForm {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    min-height: 800px;
    width: 840px;
}



.buyWatchPageInfoSide {
    position: relative; /* Context for the sticky positioning */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 25px;

}

@media only screen and (max-width: 1410px) {



    .buyWatchFormParent {
        width: 820px;
    }

    .buyWatchFormActualForm {
        width: 700px;
    }

    .buyWatchPageFormSide {
        width: 72%;
    }
    

}

@media only screen and (max-width: 1300px) {



    .buyWatchFormParent {
        width: 740px;
    }

    .buyWatchFormActualForm {
        width: 600px;
    }

    .buyWatchPageFormSide {
        width: 65%;
    }
    

}

@media only screen and (max-width: 1200px) {



    .buyWatchFormParent {
        width: 660px;
    }

    .buyWatchFormActualForm {
        width: 600px;
    }

    .buyWatchPageFormSide {
        width: 65%;
    }

    .buyWatchPageInfoSide {
        width: 35%;

    }
    

}

@media only screen and (max-width: 1100px) {

    .buyWatchFormParent {
        background-color: white;
    }

    .buyWatchPageFormSide {
        width: 60%;
    }

    .buyWatchPageInfoSide {
        width: 40%;
        padding-left: 10px;
    }

}

@media only screen and (max-width: 1080px) {

    .buyWatchFormParent {
        background-color: #a9d6e6;
        width: 900px;
    }

    .buyWatchPageFormSide {
        width: 100%;
    }

    .buyWatchPageInfoSide {
        width: 0%;
}

}

@media only screen and (max-width: 960px) {

    .buyWatchFormParent {
        width: 820px;
    }    

}

@media only screen and (max-width: 870px) {

    .buyWatchFormParent {
        width: 740px;
    }

}

@media only screen and (max-width: 780px) {

    .buyWatchFormParent {
        width: 660px;
    }

}

@media only screen and (max-width: 700px) {

    .buypageHeader {
        margin-left: 20px;
        font-size: 2.2rem;
    }

    .buyWatchFormParent {
        width: 100%;
        align-items: center;
        background-color: white;
    }

    .buyWatchFormActualForm {
        align-items: center;
        width: 100%;
    }

}

