


.sellerProfileKeyStatsDiv {
    width: 700px;
    height: 300px;
    display: grid;
    align-items: center;
    background-color: black;
    border-radius: 10px;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;  /* Gap between rows and columns */
    padding: 20px; /* Padding around the grid */
    padding-left: 30px;
    box-sizing: border-box;
}

.sellerProfileKeyStat {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
}

.keyStatHeaderAndTextDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    padding-left: 15px;
    width: 260px;
    height: 100%;
}

.keyStatIcon svg {
    width: 48px;
    height: 48px;
    color: white;
}

.keyStatHeader {
    color: white;
    font-weight: 500;
    font-size: 1.4rem;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
}

.keyStatChildText {
    color: #48cae4;
    font-weight: 400;
    font-size: 0.8rem;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 0px;
}


@media only screen and (max-width: 1325px) {


    .sellerProfileKeyStatsDiv {
        width: 660px;
        height: 280px;

    }



}

@media only screen and (max-width: 1200px) {
    .sellerProfileKeyStatsDiv {
        width: 750px;
        height: 290px;


    }
}

@media only screen and (max-width: 975px) {

    .sellerProfileKeyStatsDiv {
        background: none;
        margin-left: 40px;
    }

    .keyStatIcon svg {
        color: black;
    }

    .keyStatHeader {
        color: black;
    }

    .keyStatChildText {
        color: var(--blue-1);
        font-weight: 500;
    }

}

@media only screen and (max-width: 820px) {

    .sellerProfileKeyStatsDiv {
        width: 600px;
        margin-left: 0px;
    }

    .sellerProfileKeyStat {

        width: 86%;
        height: 50px;
    }
    
    .keyStatHeaderAndTextDiv {
        width: 260px;
        height: 100%;
    }

    .keyStatIcon svg {
        width: 38px;
        height: 38px;
        color: black;
    }

    .keyStatHeader {
        font-size: 1.2rem;
        color: black;
    }

    .keyStatChildText {
        color: var(--blue-1);
        font-weight: 500;
    }

}


@media only screen and (max-width: 820px) {

    .sellerProfileKeyStatsDiv {
        width: 500px;
        margin-left: 0px;

    }

    .sellerProfileKeyStat {
        width: 86%;
        height: 50px;
    }
    
    .keyStatHeaderAndTextDiv {
        width: 200px;
        height: 100%;
    }
}

@media only screen and (max-width: 650px) {

    .sellerProfileKeyStatsDiv {
        width: 400px;
        margin-left: 0px;

    }

    .sellerProfileKeyStat {
        width: 66%;
        height: 50px;
    }
    
    .keyStatHeaderAndTextDiv {
        width: 135px;
        height: 100%;
    }

    .keyStatHeader {
        font-size: 1rem;
    }

    .keyStatChildText {
        font-size: 0.7rem;
        width: 120px;
    }
}


