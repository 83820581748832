@keyframes pulsate {
    0% {
        background-color: #6ede8a; /* Initial color (Green) */
    }

    25% {
        background-color: #4ad66d; /* Initial color (Green) */
    }
    50% {
        background-color: #2dc653; /* Color at the mid-point of the animation (Darker Green) */
    }
    100% {
        background-color: #4ad66d; /* Final color (Green again) */
    }
}


.simpleButtonCssStyles {
    /* Insert your default styles here */
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: white; /* Normal state color (White) */
}

.simpleButtonCssStyles:hover {
    animation: pulsate 1s infinite; /* On hover, start the pulsating green animation */
}
