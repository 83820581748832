.birthInputComponentDiv {
    display: flex;
    width: 90%;
    height: 60px;
    margin-top: 23px;
}

.birthInputYearField{
    width: 105px;
    height: 40px;
    font-size: 1.1rem;
    margin-left: 20px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
}

.birthInputYearField:focus {
    outline: none; /* Remove the default outline */
    background-color: var(--silver-1);
  }

  @media (max-width: 900px) {
  
    .birthInputComponentDiv {
        display: flex;
        width: 90%;
        height: 60px;
        margin-top: 23px;
    }

}

