.footerComponentParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 650px;
    padding-top: 50px;
    box-sizing: border-box;
    background-color: black;
}

.footerComponentFirstRow {
    display: flex;
    width: 93%;
    height: 300px;
}

.firstRowTwoItems {
    display: flex;
    width: 50%;

}

.footerComponentFirstRowItem {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 300px;
    padding: 20px;
    padding-top: 0px;
    box-sizing: border-box;
}

.footerHeader {
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--purple-1);
}

.footerLinkStyle {
    display: flex;
    margin-top: 25px;
}

.footerLinkText {
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 400;
    color: white;
    transition: 0.2s color;
}

.footerPhoneNumberStyles {
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    margin-left: 20px;
}


.footerLinkText:hover {
    color: var(--gold-2);
}

.footerTelephoneDiv {
    display: flex;
    width: 100%;
    height: 50px;
    margin-top: 25px;
}

.footerComponentSecondRow {
    display: flex;
    width: 93%;
    height: 90px;
    margin-top: 20px;
}

.secondRowPaymentMethodsDiv {
    width: 500px;
    height: 100%;
}

.twoPaymentThumbNailsParent {
    display: flex;

}

.amexCardClass {
    margin-left: 20px;
}

.paymentCompanyThumbNail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 40px;
    background-color: white;
    border-radius: 6px;
}

.footerWireTransferDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 160px;
    height: 30px;
    border-radius: 6px;
    background-color: white;
    padding: 5px;
    margin-left: 20px
}

.footerComponentLastRow {
    display: flex;
    justify-content: space-between;
    width: 93%;
    height: 50px;
    margin-top: 100px;
}

.lastRowLinksDiv {
    display: flex;
    align-items: center;
}

.lastRow2LinkChunk {
    display: flex;


}

.footerLinkText2 {
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 400;
    color: #adb5bd;
    transition: 0.2s color;
}

.footerLinkText2:hover {
    color: var(--gold-2);
}

.footerSocialMediaLinksDiv {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 300px;
    height: 100%;

}

.footerSocialMediaLink {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 40px;
    height: 40px;
    color: white;
}

.marginToTAndC {
    margin-left: 20px;
}

.footerSocialMediaLink:hover {
    color: var(--gold-2);
}

@media only screen and (max-width: 1200px) {
    .footerPhoneNumberStyles {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 1000px) {
    .footerHeader {
        font-size: 1rem;
    }

    .footerLinkText {
        font-size: 0.95rem;
    }

    .footerPhoneNumberStyles {
        font-size: 1.1rem;
    }

    .footerComponentFirstRow {
        width: 95%;
    }

    .footerLinkText2 {
        font-size: 1rem;
    }

}

@media only screen and (max-width: 1000px) {

    .footerLinkText2 {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 800px) {

    .footerComponentParent {
        height: 1000px;
    }

    .footerComponentFirstRow {
        flex-direction: column;
        width: 90%;
        height: 600px;
    }

    .firstRowTwoItems {
        width: 100%;
    }

    .footerHeader {
        font-size: 1.2rem;
    }

    .footerLinkText {
        font-size: 1.1rem;
    }

    .footerComponentLastRow {
        height: 60px;
    }

    .lastRowLinksDiv {
        flex-direction: column;
        align-items: flex-start;
    }

    .marginToTAndC {
        margin-left: 0px;
    }

    .footerLinkText2 {
        font-size: 1.1rem;
    }

}

@media only screen and (max-width: 600px) {

    .footerHeader {
        font-size: 1rem;
    }

    .footerLinkText {
        font-size: 0.95rem;
    }

    .footerComponentLastRow {
        flex-direction: column;
        margin-top: 20px;
        height: 190px;
    }

    .footerPhoneNumberStyles {
        font-size: 1.1rem;
    }

    

    .footerSocialMediaLinksDiv {
        width: 90%;
    }
}

@media only screen and (max-width: 520px) {

    .footerLinkText {
        font-size: 0.9rem;
    }



}

@media only screen and (max-width: 460px) {

    .footerComponentParent {
        height: 1720px;
    }

    .footerComponentFirstRow {
        height: 1200px;
    }

    .firstRowTwoItems {
        flex-direction: column;
        width: 100%;
    }

    .footerComponentFirstRowItem {
        width: 100%;
    } 

    .footerHeader {
        font-size: 1.3rem;
    }
    

    .footerLinkText {
        font-size: 1.1rem;

    }

    .footerPhoneNumberStyles {
        font-size: 1rem;
    }

    .footerComponentSecondRow {
        height: 180px;
    }

    .paymentMethodsParent {
        flex-direction: column;
        justify-content: space-evenly;
        height: 140px;
    }

    .amexCardClass {
        margin-left: 0px;
    }

}