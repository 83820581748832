.chatBubbleAndInitialsParent {
    display: flex;
    margin: 5px;
    min-height: 40px;
    flex-shrink: 0;

}

.chatBubbleParent {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    box-sizing: border-box;
    min-height: 40px;
    border-radius: 12px;
    background-color: var(--silver-2);
}



  .chatBubbleText {
    font-size: 1rem;
    margin: 0px;
    word-wrap: break-word; /* Add this */

  }

  .profileInitialsDiv {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 17px;
    margin-right: 10px;
    background-color: black;
  }