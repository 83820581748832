.watchFunctionsCheckBoxesParentDiv {
    display: flex;
    margin-top: 10px;
    width: 95%;
    height: 400px;
    flex-direction: column;

}

@media (max-width: 570px) {

    .watchFunctionsCheckBoxesParentDiv {
        height: 600px;
    }

}