.ConversationsSection {
    display: flex;
    flex-direction: column;
    background-color: gray;
    width: 1200px;

}

.conversationsSectionBackLinkText {
    margin: 0px;
    color: black;

}