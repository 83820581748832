.header {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0px;
  }

  .smallHeader {
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
    margin: 0px;
  }

  .smallThickText {
    font-size: 1rem;
    font-weight: 500;
    color: black;
    margin: 0px;
  }

  .smallText {
    font-size: 1rem;
    font-weight: 400;
    color: black;
    margin: 0px;
  }

  .text {
    margin: 0px;
  }

  .standardButtonTextStyle {
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 500;
    margin: 0px;
  }



  .underLineDiv {
    width: 0px;
    height: 1px;
    margin-top: 4px;
    --underline-width: 300px; /* Default width */
    background-color: black;
  }

  .buyWatchFormStandardDiv {
    display: flex;
    width: 100%;
}


  .animatedUnderlineExpand {
    animation: expandUnderline 0.3s forwards;
  }
  
  .animatedUnderlineContract {
    animation: contractUnderline 0.3s forwards;
  }


  .stickyDiv {
    position: sticky;
    top: 7px;
    margin: 0px;
  }

  .standardCheckBox {
    width: 20px;
    height: 20px;
  }

  .visitProfileButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 45px;
    border: 3px solid black;
    background-color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s background-color ease, 0.5s color ease-out;
  }

  .visitProfileButton:hover {
    background-color: black;
    color: white;
  }

@keyframes expandUnderline {
    from {
      width: 0px;
    }
    to {
        width: var(--underline-width); /* Use the CSS variable here */
    }
  }

  @keyframes contractUnderline {
    from {
        width: var(--underline-width); /* Use the CSS variable here */
    }
    to {
      width: 0;
    }
  }


  @keyframes pulsate {
    0% {
        background-color: #6ede8a; /* Initial color (Green) */
    }

    25% {
        background-color: #4ad66d; /* Initial color (Green) */
    }
    50% {
        background-color: #2dc653; /* Color at the mid-point of the animation (Darker Green) */
    }
    100% {
        background-color: #4ad66d; /* Final color (Green again) */
    }
}

@keyframes fadeInPageUpwards {
  from {
    opacity: 0;
    transform: translateY(10px); /* Start from 10px below */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.resetLinkStyle {
  text-decoration: none;
  color: black;
}

.standardFlexDiv {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 600px) {

  .header {
    font-size: 2rem;
  }

}


@media only screen and (max-width: 480px) {

  .header {
    font-size: 1.6rem;
  }

}