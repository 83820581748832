.addPaymentCardParent {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 10;
    display: flex;

}

.addCardTemplate {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 70px;
    padding: 25px;
    width: 610px;
    height: 700px;
    background-color: white;
    border-radius: 12px;

}

.addCardTemplateHeaderBox {
    position: relative;
    display: flex;
    width: 400px;
    height: 45px;

    background-color: blue;
    border-radius: 6px;

    color: black;

}

.addCardTemplateHeaderInput {
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    font-weight: 400;
    border: none;
    margin: 0px;
}

.addCardTemplateHeaderInput:focus {
    outline: none;
}

.invalidInputValuePopupAlert {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 40px;
    border-radius: 6px;
    top: 0px;

    background-color: #e5e5e5;
    color: white;

}

.invalidInputValuePopupAlert::after {
    content: '';
    position: absolute;
    top: 10px;
    left: -7px; /* Adjust this value according to the size of the triangle */

    width: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #e5e5e5; /* Same color as the popup background */
}




.cardHeaderAnimatedUnderline {
    position: absolute;
    height: 2px;
    background-color: black;
    transition: width 0.3s ease;
    bottom: 2px;
}

.addCardTemplateSmallHeader {
    font-size: 0.9rem;
    color: black;
    font-weight: 400;
    margin: 0px;
    margin-top: 10px;
}

.addCardTemplateInputBox {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;

    margin-top: 2px;

}

.addCardTemplateInput {
    font-size: 1.1rem;
    height: 50px;
    padding-left: 5px;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid #e9ecef;
    background-color: #e9ecef;
    transition: all 0.5s ease;
}


.addCardTemplateInput:focus {
    outline: none;
    background-color: #ced4da;
    border: 1px solid black;
}

.addPaymentCardDoneButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 50px;

    border-radius: 6px;
    border: 2px solid black;
    margin-top: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.addPaymentCardDoneButton:hover {
    background-color: #2dc653;
    color: white;
    border: none;
    font-weight: 600;
}


.addPaymentCardExitButton {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    cursor: pointer;
}



