.overviewPageParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    padding: 45px;
    box-sizing: border-box;
}

.overviewPageListingSummaryParentDiv {
    display: flex;
    width: 95%;
    min-height: 750px;
    margin-top: 60px;
    background: linear-gradient(to bottom right, #0096c7, #03045e);
    border-radius: 8px;
    border: 3px solid black;

}

.overviewPageListingSummarySideChildDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28%;
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 6px;
} 

.listingInformationMediumDiv {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    min-height: 100px;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 15px;    
    padding-bottom: 25px;
}

.listingFeeInformationDiv {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: black;
    margin-top: 20px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    min-height: 150px;
    border: 3px solid #0077b6;
}

.listingInformationTextDiv {
    border-radius: 6px;
    background-color: black;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    min-height: 320px;
    border: 3px solid #0077b6;

}

.saveListingInformationDiv {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background-color: black;
    margin-top: 20px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    min-height: 210px;
    border: 3px solid #0077b6;
}

.overviewPageSub1200SideDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 34%;
}

.overviewPageListingSummaryCenterChildDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 44%;
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    background-color: black;
}

.sellerGuidelinesTextDiv {
    border-radius: 6px;
    background-color: black;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    min-height: 250px;
    border: 3px solid #0077b6;

}

.overviewListingPreviewParentDiv2 {
    position: relative;
    width: 318px;
    height: 413px;
    margin-top: 50px;
    box-sizing: border-box;
    border-radius: 9px;
    border: 4px solid #003251;
    animation: complexShake 6s infinite; /* Adjust timing as you like */

}

.overviewListingPreviewParentDiv2WithoutShake {
    position: relative;
    width: 318px;
    height: 413px;
    margin-top: 50px;
    box-sizing: border-box;
    border-radius: 9px;
    border: 4px solid #003251;

}


/* Style the stars */
.star {
    position: absolute;
    animation: glitterStar 5s infinite;
    z-index: 1;  /* Optional, if you want the stars to be above other content */

}

/* Style the SVG within the star */
.star svg {
    color: #fff75e;
    width: 1.2em;
    height: 1.2em;
    animation: spinStar 2.5s cubic-bezier(0.25, 0.1, 0.25, 1) infinite; 

}

/* Keyframes for star glittering */
@keyframes glitterStar {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}


/* Individual animation for each star */
#star1 { animation-delay: 0.5s; top: 15px; right: 35px; }
#star2 { animation-delay: 1s; top: 40px; right: 15px; }
#star3 { animation-delay: 1.5s; top: 5px; right: 5px; }
/* Add more stars with individual positions and delays */



@keyframes complexShake {
    0% { transform: rotate(0deg); }
    19% { transform: rotate(6deg); }
    22% { transform: rotate(5deg); }
    25% { transform: rotate(6deg); }
    28% { transform: rotate(5deg); }
    31% { transform: rotate(6deg); }
    50% { transform: rotate(0deg); }
    69% { transform: rotate(-6deg); }
    72% { transform: rotate(-5deg); }
    75% { transform: rotate(-6deg); }
    78% { transform: rotate(-5deg); }
    81% { transform: rotate(-6deg); }
    100% { transform: rotate(0deg); }
  }

  @keyframes spinStar {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.overviewPageUpgradeToTopDiv {
    display: flex;
    flex-direction: column;
    width: 810px;
    height: 268px;
    background-color: white;
    border: 3px solid black;
    box-sizing: border-box;
    border-radius: 8px;
}

.overviewPageUpgradeToTopHeaderDiv {
    width: 100%;
    height: 25%;
    background-color: black;
    box-sizing: border-box;
}

.overviewPageUpgradeToTopContentDiv {
    display: flex;
    width: 100%;
    height: 75%;
    background-color: white;

    box-sizing: border-box;
    border-radius: 8px;

}



.overviewPageUpgradeToTopContentDivFirstHalf {
    display: flex;
    flex-direction: column;
    width: 59%;
    height: 100%;
    padding: 20px;
    padding-left: 30px;

    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;

}

.overviewPageUpgradeToTopContentDivSecondHalf {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 41%;
    height: 100%;
    padding: 20px;
    padding-left: 30px;
    overflow: hidden;
    padding: 20px;
    padding-left: 30px;
    box-sizing: border-box;
    border-radius: 8px;

} 

@media (max-width: 860px) { 

    .overviewPageUpgradeToTopHeaderDiv {
        height: 70px;
    }
    
    .overviewPageUpgradeToTopContentDiv {
        flex-direction: column;
        height: 430px;
    }

    .overviewPageUpgradeToTopDiv {
        width: 360px;
        height: 500px;
    }


    
    .overviewPageUpgradeToTopContentDivFirstHalf {
        width: 100%;
        align-items: flex-start;
        padding: 20px;
        padding-top: 40px;
        height: 225px;

    }

    .overviewPageUpgradeToTopContentDivSecondHalf {
        align-items: center;
        width: 100%;
        height: 200px;
    }
}


.upgradeDivBottomImageDiv {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: -40%; /* Hide the bottom 1/3 of the image */
    left: 10;
    width: 265px;  /* Adjust width as needed */
    background-color: white;
    border-radius: 6px;
    border: 3px solid #003251;
    opacity: 1.0;


  }

.upgradeDivBottomImage {
    width: 87%;
    border-radius: 4px;

}

.upgradeDivTopIconHoverDiv {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -40px;
    width: 70px;
    height: 70px;
}

.upgradeDivTopIconParagraph {
    position: absolute;
    left: -23px;
    top: 6px;
    transform: rotate(-45deg);  /* Add this line */

}


.overviewPageUpgradeToTopContentDivRow {
    display: flex;
    width: 100;
    height: 30px;

}

.overviewPageButtonsParentDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 90%;
    height: 50px;
}

@media only screen and (max-width: 1300px) {

    .listingInformationMediumDiv {
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 100%;
        min-height: 100px;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 10px;    
        padding-bottom: 15px;
    }
    
    .listingFeeInformationDiv {
        background: none;
        border: none;
        margin-top: 20px;
        padding: 0px;
    }

    .listingInformationTextDiv {
        background: none;
        margin-top: 20px;
        padding: 0px;
        width: 100%;
        min-height: 320px;
        border: none;
    
    }

    .saveListingInformationDiv {
        background: none;
        border: none;
        margin-top: 0px;
        padding: 5px;
        min-height: 180px;
    }

    .sellerGuidelinesTextDiv {
        margin-top: 20px;
        padding: 0px;
        background: none;
        border: none;
    
    }

}

@media only screen and (max-width: 1200px) {

    .overviewPageListingSummarySideChildDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 56%;
        height: 100%;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 6px;
    } 

    .listingInformationTextDiv {
        margin-top: 0px;
        padding: 0px;
        width: 100%;
        min-height: 90px;
    
    }

    .sellerGuidelinesTextDiv {
        margin-top: 0px;
        padding: 0px;
        width: 100%;
        min-height: 100px;
    
    }

}

@media only screen and (max-width: 1100px) {

    .overviewPageParentDiv {
        padding: 0px;
    }

    .overviewPageListingSummaryParentDiv {
        width: 100%;
        border-radius: 0px;
        border: none;
    }

}



@media only screen and (max-width: 1100px) {



    .overviewPageListingSummaryParentDiv {
        width: 80%;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
    }

    .overviewPageListingSummaryCenterChildDiv {
        width: 100%;
        border-radius: 10px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .overviewPageListingSummarySideChildDiv {
        width: 100%;
    }

    .overviewPageUpgradeToTopDiv {
        align-self: center;
    }

}

@media only screen and (max-width: 600px) {

    .overviewPageSub1200SideDiv {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-height: 520px;
    }

    .listingFeeInformationDiv {
        width: 100%;
        background: none;
        border: none;
        margin-top: 20px;
        padding: 0px;
    }

}


