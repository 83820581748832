.sellerProfilePageParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 1000px;
    box-sizing: border-box;
    padding-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.sellerProfileParentDiv {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 1600px;
    background: linear-gradient(to bottom right, var(--silver-1), var(--silver-3));
    margin-top: 50px;
    padding: 25px;
    padding-bottom: 50px;
    box-sizing: border-box;
    border-radius: 8px;

}

.sellerProfileNameAndImageDivAndKeyStatsDiv {
    display: flex;
    width: 100%;
    min-height: 300px;
    border-radius: 8px;
    box-sizing: border-box;

}

.sellerProfileImagePlusInfoParentDiv {
    display: flex;
    width: 45%;
    height: 100%;
}

.sellerProfileImageDiv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 45%;
    height: 90%;
    margin-top: 5px;
    box-sizing: border-box;

}

.sellerProfileImage {
    width: 180px;
    height: 180px;
}

.sellerProfileNameAndInfoDiv {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    padding-left: 20px;
    box-sizing: border-box;
    width: 55%;
    height: 90%;
    margin-top: 5px;

}

.sellerProfileName {
    font-size: 2rem;
    font-weight: 500;
    color: '#003251';
    margin: 0px;
}






.sellerProfileReviewsParentDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 300px;
    border-radius: 6px;
    margin-top: 40px;
    padding: 15px;
    box-shadow: 5px 5px 10px 1px rgba(0,0,0,0.3);
    background: linear-gradient(to bottom right, var(--gold-1), var(--silver-3));
}

.sellerReviewsSummaryDiv {
    display: flex;
    width: 100%;
    min-height: 215px;
    box-sizing: border-box;
    background-color: black;
    border-radius: 8px;
    margin-top: 15px;

}

.sellerReviewsSummaryStatsDiv {
    display: flex;
    flex-direction: column;
    width: 360px;
    height: 200px;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 20px;
    padding-left: 25px;
    background-color: black;
    border-radius: 6px;
}

.sellerReviewsChartDiv {
    display: flex;
    flex-direction: column;
    width: 900px;
    height: 200px;
    border-radius: 6px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 30px;
    padding: 20px;
    background-color: black;
}

.sellerReviewScoreDivRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.sellerReviewsSummaryDivRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.reviewsChartBar {
    width: 80%;
    height: 9px;
    margin-left: 40px;
    border: 1px solid white;
    border-radius: 5px;
    
}

.reviewsChartBarFilled {
    height: 9px;
    background-color: var(--blue-1);
    border-radius: 4px;

}

.reviewChartDivTextBox {
    width: 90px;
}

.sellerTextReviewsParentDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    min-height: 300px;
    margin-top: 40px;
    border-radius: 8px;
}

.sellerTextReviewsDropDownsParentDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 14px;
    padding-right: 14px;
    background-color: black;
    border-radius: 35px;
    width: 86%;
    height: 60px;
}

.singleUserReviewSeparatorDiv {
    margin-top: 0px;
    height: 5px;
    width: 100%;
    background-color: white;
}


@media only screen and (max-width: 1325px) {



    .sellerProfilePageParentDiv {
        align-items: center;
    }

    .sellerProfileNameAndImageDivAndKeyStatsDiv {
        display: flex;    
        width: 100%;
        align-items: flex-start;
    }

    .sellerProfileImagePlusInfoParentDiv {
        display: flex;
        width: 42%;
        height: 100%;
    }

    .sellerProfileImageDiv {

        width: 45%;
        height: 90%;
        margin-top: 5px;
        box-sizing: border-box;
    
    }

    .sellerProfileImage {
        width: 160px;
        height: 160px;
    }
    
    .sellerProfileNameAndInfoDiv {
        display: flex;
        flex-direction: column;
        padding-top: 7px;
        padding-left: 20px;
        box-sizing: border-box;
        width: 55%;
        height: 90%;
        margin-top: 5px;
    
    }



    .sellerReviewsSummaryDiv {
        flex-direction: column;
    }


    .sellerReviewsChartDiv {
        width: 90%;
    }


    .hide-below-1325 {
        display: none ;
      }


}

@media only screen and (max-width: 1200px) {

    .sellerProfileParentDiv {
        padding: 15px;
    }

    .sellerProfileNameAndImageDivAndKeyStatsDiv {
        flex-direction: column;
        align-items: center;
    }

    .sellerProfileImagePlusInfoParentDiv {
        display: flex;
        width: 82%;
        height: 250px;
    }

    .sellerProfileImageDiv {

        width: 50%;
        height: 90%;
        margin-top: 5px;
        box-sizing: border-box;
    
    }

    .sellerProfileImage {
        width: 200px;
        height: 200px;
    }
    
    .sellerProfileNameAndInfoDiv {

        margin-top: 15px;
    
    }

}




@media only screen and (max-width: 975px) {
    .hide-below-975 {
        display: none;
    }

    .sellerProfilePageParentDiv {
        padding-left: 20px;
        padding-right: 20px;
    }

}



@media only screen and (max-width: 800px) {


    .sellerProfileImageDiv {
        width: 60%;
        
    }

    .sellerProfileName {
        margin-top: 5px;
        font-size: 1.8rem;
    }

    .sellerReviewsSummaryStatsDiv {
        width: 90%;
    }

    .sellerReviewsChartDiv {
        width: 80%;
        padding-left: 0px;
        margin-left: 30px;
    }

    .reviewChartDivTextBox {
        width: 120px;
    }

    .reviewsChartBar {
        width: 70%;
    }

    .hide-below-800 {
        display: none ;
      }



}

@media only screen and (max-width: 650px) {

    .sellerProfilePageParentDiv {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0px;
        background: linear-gradient(to bottom right, var(--silver-1), var(--silver-3));
    }

    .sellerProfileParentDiv {
        border-radius: 0px;
        background: none;
    }

    .sellerProfileImagePlusInfoParentDiv {
        width: 100%;
        height: 220px;
    }

    .sellerProfileImageDiv {
        width: 40%;
    }

    .sellerProfileNameAndInfoDiv {
        width: 60%;
    }

    .sellerProfileImage {
        width: 132px;
        height: 132px;
        margin-top: 16px;
    }

    .sellerProfileName {
        margin-top: 5px;
        font-size: 1.4rem;
    }

    .sellerReviewsSummaryDiv {
        height: 440px;
    }

    .sellerReviewsSummaryDivRow {
        width: 270px;
    }

    .sellerTextReviewsDropDownsParentDiv {
        flex-direction: column;
        height: 90px;
        background: none;
    }




}

