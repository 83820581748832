.thumbnailsAndConversationAndInputAreaParent {
    display: flex;
    border-radius: 10px;
    height: 560px;
    margin-top: 10px;
    margin-left: 20px;
    align-items: flex-end;

}

.convoActionsPanel {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 340px;
    height: 562px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    
}



.conversationAndInputAreaParent {
    display: flex;
    flex-direction: column;
    height: 560px;
    width: 980px;
    border: 1px solid black;
    border-left: none;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;


}

.conversationParent {
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Makes it scrollable if content exceeds this height */
    width: 980px;
    height: 510px;
    padding: 5px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0px;
    border-top-right-radius: 10px;
}

@media only screen and (max-width: 1400px) {
    .conversationAndInputAreaParent {
        width: 700px;
    }

    .conversationParent {
        width: 700px;
    }
}

.bounce-effect {
    transition: transform 0.2s ease-in-out;
  }