.newNavbarParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 120px;
    padding-top: 5px;
    align-items: center;
    background-color: #eeefee;
    box-sizing: border-box;
}

.newNavBarLogoAndSearchParentDiv {
    display: flex;
    padding-top: 8px;
    box-sizing: border-box;
    width: 100%;
    height: 81px;
}





.newNavbarLogoParentDiv {
    display: flex;
    width: 8%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 5px;
    align-items: center;
    justify-content: center;
}

.newNavBarLogoDiv {
    width: 64px;
    height: 64px;
}

.newNavbarSearchBarParentDiv {
    display: flex;
    width: 92%;
    height: 100%;

}

.newNavBarLinksParentDiv {
    width: 100%;
    height: 32px;
}

.newNavBarLink {
    display: flex;
    width: 25%;
    height: 100%;
    align-items: center;
    justify-content: center;
    
}

.newNavbarLinkTextStyle {
    font-size: 0.95rem;
    color: black;
    transition: all 0.3s ease;
}

.newNavbarLinkTextStyle:hover {
    color: var(--blue-3);
}

@media (max-width: 900px) {
    .newNavbarLogoParentDiv {
        width: 12%;
    }

    .newNavbarSearchBarParentDiv {
        width: 88%;
        height: 100%;
    }

    .newNavBarLogoDiv {
        width: 65px;
        height: 65px;
    }
}

@media (max-width: 600px) {

    .newNavbarLinkTextStyle {
        font-size: 0.9rem;
    }


}

@media (max-width: 500px) {

    .newNavbarLinkTextStyle {
        font-size: 0.8rem;
    }

    .newNavbarLogoParentDiv {
        margin-left: 10px;
    }


}

@media (max-width: 420px) {

    .newNavbarLinkTextStyle {
        font-size: 0.7rem;
    }

    .newNavbarLogoParentDiv {
        width: 15%;
    }

    .newNavbarSearchBarParentDiv {
        justify-content: center;
        width: 85%;
        margin-left: 0px;
    }

    .newNavBarLogoDiv {
        width: 55px;
        height: 55px;
    }

}