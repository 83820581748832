.icon-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-link .text {
    margin-left: 10px;
    font-size: 17px;
    font-weight: 450;
}

.icon-link .selected-icon {
    display: none;
}

.sidebar-link {
    text-decoration: none;
    color: inherit; /* or set to any color you want */
    transition: color 0.3s ease-in; /* Adding transition */
    color: var(--blue-1); /* default color for unselected link */


}

.sidebar-link:hover, .selected-link {
    color: #00296b; /* set the color you want on hover and when selected */
}

.selected-link .icon {
    display: none;
}

.selected-link .selected-icon {
    display: inline-block;
}