.profileHomeSidebarDiv {
    width: 250px;
    height: 400px;
    margin-top: 120px;
}

@media only screen and (max-width: 1000px) {
    .profileHomeSidebarDiv {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50px;
        height: 50px;
        margin-top: 0px;
    }


}