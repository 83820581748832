.conversationSearchParent {

    display: flex;
    align-items: center;
    width: 340px;
    height: 60px;
    background-color: white;
    border: 1px solid black;
    border-top-left-radius: 10px;
    border-bottom: none;
}

.conversationSearchTextInputParent {
    position: relative;
    display: flex;
    width: 290px;
    height: 50px;
    align-items: center;


}

.conversationSearchLogoParent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-right: 5px;

    cursor: pointer;
}

.conversationSearchTextInput {
    position: absolute;
    left: 10px;
    height: 35px;
    width: 270px;
    border: none;
    outline: none;
    padding-top: 9px;  /* Add or adjust padding-top to move the text down */
    box-sizing: border-box;
    font-size: 1.15rem;
    font-family: Arial, Helvetica, sans-serif;
}

.conversationSearchNormalUnderline {
    position: absolute;
    bottom: 5px;
    height: 1px;
    background-color: black;
    opacity: 0;
    left: 10px;
    right: 10px;
    transition: all 0.3s ease-in-out;

}

.conversationSearchDynamicUnderline {
    position: absolute;

    bottom: 4.5px;
    left: 50%;
    right: 50%;
    height: 1.5px;
    background-color: black;
    transition: all 0.2s ease-in-out;

}

.conversationSearchDynamicUnderlineHover {
    opacity: 0.3;
}

.conversationSearchDynamicUnderlineActive {
    left: 10px;
    right: 10px;
    background-color: black;
}

.searchIconTransition {
    transition: color 0.3s ease-in-out;
  }