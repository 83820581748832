.purchasedWatchPreviewParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    min-height: 500px;
    padding: 15px;
    box-sizing: border-box;
    background-color: var(--silver-2);
    border-radius: 12px;
    transition: height 0.3s ease;
}

.purchasedWatchSummaryLink {
}

.purchasedWatchPreviewSliderParentDiv {
    position: relative;
    width: 370px;
    height: 370px; 
    border-radius: 12px;
}

.purchasedWatchsliderStyles {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
}

.purchasedWatchPreviewImage {
    width: 370px;
    height: auto;
    border-radius: 12px;
}

.purchasedWatchPreviewSellerHeader {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0px;
    margin-top: 18px;
    margin-left: 6px;
}

.purchasedWatchPreviewSellerComment {
    font-size: 1.05rem;
    font-weight: 400;
    margin: 0px;
    margin-top: 8px;
    margin-left: 6px;
}

.purchasedWatchPreviewSellerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    margin-top: 5px;
    margin-left: 6px;

}

.purchasedWatchPreviewSellerLocation {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 50px;


    cursor: pointer;
}

.purchasedWatchSummaryDropdownContent {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
}
