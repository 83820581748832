.divStyle {
    display: flex;
    transition: margin-left 0.2s, margin-top 0.2s;
}

.spanStyle {
    color: black;
    font-size: 16px;
    font-weight: 450;
    transition: text-decoration 0.4s, color 0.4s;
}