.userConversationThumbnailParent {
    position: relative;
    display: flex;
    align-items: center;
    width: 340px;
    height: 100px;
    padding: 10px;
    box-sizing: border-box;

    border: 0px solid #000000;

    cursor: pointer;
    transition: background-color 0.1s ease;
}

.isConversationMarkedAsFinished {
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #14213d;
    border-radius: 10px;
}


.thumbnailProfilePic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background-color: black;
}

.thumbnailProfilePic img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 35px;
}

.thumbnailProfilePic p {
    font-size: 1.4rem;
    font-weight: 500;
    color: white;
}

.conversationThumbnailUsernameAndInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 250px;
    height: 80px;
    box-sizing: border-box;
    padding-left: 12px;
}

.conversationThumbnailUsername {
    font-size: 1.05rem;
    margin: 0px;
}

.conversationThumbnailSmallInfoDiv {
    display: flex;
    height: 50px;
    width: 230px;
    box-sizing: border-box;
    padding-top: 5px;
}

.conversationThumbnailSenderDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    background-color: #14213d;
    border-radius: 10px;
    flex-shrink: 0; /* Add this line */

}

.conversationThumbnailSenderText {
    font-size: 0.7rem;
    font-weight: 500;
    color: white;
}

.conversationThumbnailText {
    max-height: calc(1.2em * 3); /* 1.2em is the line height, 3 is the number of lines you want to display */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box; /* Important for applying line clamping */
    -webkit-line-clamp: 3; /* Truncates text after 3 lines */
    -webkit-box-orient: vertical; /* Required to make line-clamp work */
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0px;
    margin-left: 5px;
    line-height: 1.2em;
}