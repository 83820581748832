.addImageThumbNailDiv {
    display: flex;
    flex-direction: column;
    width: 160px;
    height: 160px;
    border: 3px dashed white;
    background-color: #000;
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.addImageFileInput {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

@media (max-width: 1300px) {

    .addImageThumbNailDiv {
        height: 140px;
        width: 140px;
    }
}

@media (max-width: 1240px) {

    .addImageThumbNailDiv {
        height: 115px;
        width: 115px;
    }
}

@media (max-width: 1031px) {

    .addImageThumbNailDiv {
        width: 160px;
        height: 160px;
    }

}

@media (max-width: 900px) {

    .addImageThumbNailDiv {
        height: 140px;
        width: 140px;
    }
}

@media (max-width: 820px) {

    .addImageThumbNailDiv {
        height: 115px;
        width: 115px;
    }
}