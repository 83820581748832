.inputClickableBoxParentDiv {
    display: flex;
    
    flex-direction: column;
    background-color: white;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.3s ease; /* This line adds the smooth effect */

}

.inputClickableBoxImageDiv {
    display: flex;
    width: 100%;
    height: 56%;
    justify-content: center;
}

.inputClickableBoxImage {
    width: 90px;
    height: 90px;

}

.inputClickableBoxTextAndCheckParentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    width: 100%;
    height: 30%;
}