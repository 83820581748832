.slide-up-fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  /* final state of the div */
  .slide-up-fade-in.active {
    opacity: 1;
    transform: translateY(0);
  }

  .settingsParentDiv {
    display: flex;
    width: 100%;
    height: 100vh;
    margin-top: 30px;
  }

  @media (max-width: 1000px) {

    .settingsParentDiv {
      justify-content: center;
    }

  }