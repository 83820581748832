.ImageBubbleAndInitialsParent {
    display: flex;
    margin: 5px;
    min-height: 40px;
    flex-shrink: 0;

}

.ImageBubbleParent {
    display: flex;
    box-sizing: border-box;
    border-radius: 12px;
}

.ImageInBubble {
    max-width: 280px;
    max-height: 280px;
    border-radius: 8px;
    object-fit: contain; /* This will make sure the aspect ratio of the image is maintained */
    height: auto;
    cursor: pointer;
}

.ImageInBubble:hover {
    opacity: 0.9;
}

.ImageBubbleOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Make sure the z-index is higher than other elements */
  }

  .ImageBubbleOverlayImage {

        max-width: 70%;
        max-height: 70%;
        border-radius: 10px;

  }


  .profileInitialsDiv {
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 17px;
    margin-right: 10px;
    background-color: black;
  }