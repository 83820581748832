.profileHomeHamburgerButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    border: none;
    border-radius: 6px;
    background: none;

    width: 50px;
    height: 50px;
    z-index: 0;
    color: black;
}


.profileHomeHamburgerLinksParentDiv {
    position: absolute;
    display: flex;
    top: 0px;
    left: -280px; /* start off-screen */

    background-color: white;
    overflow: hidden; /* Hide the content when width is 0 */
    transition: transform 0.3s ease-out, opacity 0.3s ease-out; /* Animate the transform property */
    transform: translateX(0); /* Initial state */
    opacity: 0;
    border-radius: 6px;
    z-index: 1;
    height: 290px;
    width: 280px;
}

.hamburgerLinksDiv {
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 250px;
}

.profileHomeHamburgerLinksParentDiv.open {
    transform: translateX(280px); /* Move to the right to its original position */
    opacity: 1;
}

  .hamburgerPopupCloseButton {
    margin-left: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    background: none;
    border: none;
  }

.hamburgerPopupCloseButton:hover {
    background-color: var(--silver-2);
}

@media only screen and (max-width: 1000px) {

.profileHomeHamburgerButton {
    top: 18px;
}

}

