.watchListing {
    position: relative;
    width: 310px;
    height: 405px;
    /* add more styles as necessary */
}

.imageSliderDivContainer {


}

.imageSliderDiv {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 260px;
    padding: 0;
    margin: 0;
    background-color: white;
    border-radius: 8px;

}



.listingImageStyling {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    margin: 0;
    border-radius: 8px;
    z-index: 2;  /* added this line */
    transition: transform 0.5s ease;

}

.imageSliderDiv:hover .listingImageStyling {
    transform: scale(1.1);
}

.listingInfoDiv {
    margin-top: 5px;
    width: 100%;
    height: 23%;
    background-color: white;
    box-sizing: border-box;
}

.listingInfoButton {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 0px;
    background-color: black;
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.sellerCommentDiv {
    position: relative;
    margin: 5px;
    border-radius: 4px;
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    width: 100%;
}

.sellerInfoHoverDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: rgba(0, 0, 0, 0.8); /* Black background with some transparency */
    color: white; /* White text color */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 82%; /* Control the width */
    top: -60px; /* Control the position from the top, you can adjust this according to your needs */
    right: 27px; /* Position it at the right side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: justify; /* Justify the text */
}  

.sellerPriceAndLocationDiv {
    
    box-sizing: border-box;
    margin-left: 4px;
    display: flex;
    width: 100%;
}

.sellerPriceInfoDiv {
    display: flex;
    flex-direction: column;
    width: 90%;
}


.sellerLocationDiv {
    
    width: 10%;
    display: flex;
    padding-top: 2px;
    flex-direction: column;
    align-items: center;
}

.sellerLocationIconDiv {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
}
.locationInfoHoverDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: rgba(0, 0, 0, 0.8); /* Black background with some transparency */
    color: white; /* White text color */
    padding: 7px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 250px; /* Control the width */
    top: -60px; /* Control the position from the top, you can adjust this according to your needs */
    right: 0px; /* Position it at the left side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: justify; /* Justify the text */
}


.listingLinkStyle {


    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 5px;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    height: 380px;
    width: 260px;
}