.signOutSectionParent {
    width: 100%;
    height: auto;
    padding-top: 30px;
    box-sizing: border-box;
}

.signOutSectionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border: 2px solid black;
    background-color: white;
    border-radius: 6px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.signOutSectionButton:hover {
    background-color: var(--blue-1);
}

.signOutSectionButton:hover .signOutText {
    color: white;
}

