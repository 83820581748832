.exploreParentDiv {
    width: 100%;
    min-height: 900px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


.slide-up-fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .slide-up-fade-in.active {
    opacity: 1;
    transform: translateY(0);
  }


.exploreGridDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 90%;
    height: 560px;
    margin-top: 30px;
    background-color: #051923;
    border-radius: 12px;
}

.exploreChildDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

@media (max-width: 1350px) {

  .exploreGridDiv {
    height: 520px;
  }

}

@media (max-width: 1280px) {

  .exploreParentDiv {
    justify-self: center;
  }

  .exploreGridDiv {
    height: 480px;
  }

}

@media (max-width: 1180px) {

  .exploreGridDiv {
    height: 420px;
  }

}

@media (max-width: 750px) {

  .exploreGridDiv {
    height: 360px;
  }

}

@media (max-width: 520px) {

  .exploreParentDiv {
    justify-content: center;
  }

  .exploreGridDiv {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    min-height: 460px;
    height: auto;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-evenly;
    margin-top: 30px;
    box-sizing: border-box;
    background-color: #051923;
    border-radius: 12px;
  }

}


