.imageInputAreaParent {
    display: flex;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    width: 960px;
    height: 80px;
    align-items: center;  /* Center the images vertically */
    overflow-x: auto;  /* Enable scrolling if images exceed container width */
    background-color: var(--silver-2);
    border: 1px solid gray;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);  /* More pronounced shadow */

}

.pendingImageContainer {
    position: relative;
    width: 50px;
    height: 50px;

    border-radius: 3px;
    margin: 0 5px;
}

.pendingImage {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    
  }

.pendingImageRemoveButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -8px;
    top: -6px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 0px;
    opacity: 0.7;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    border: 1px solid black;

    background-color: white;
    transition: background-color 0.1s ease;
}

.pendingImageRemoveButton:hover {
    background-color: red;
}

@media only screen and (max-width: 1300px) {
    .imageInputAreaParent {
        width: 680px;
    }
}