.popularWatchesParentDiv {
    position: relative;
    width: 95%;
    height: 465px;
    background-color: #012a4a;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}



.slick-slider {
    max-width: 100%; /* Adjust this value to your needs */

}

.slick-dots {
    bottom: 0px; /* position the dots further away from the slider */

  }
  
  .slick-dots li {
    margin: 0 5px; /* adjust the space between dots */
  }
  
  .slick-dots li button:before {
    content: "" !important;
    width: 20px; /* set width of the rectangle */
    height: 4px !important; /* set width of the rectangle */
    background-color: var(--blue-2); /* set the color of the rectangle */
    display: inline-block; /* make it inline */
    margin: 0 10px; /* increase margin if needed */
    transition: width 0.6s ease; /* animate width */

  }
  
  .slick-dots li.slick-active button:before {
    width: 23px; /* set width of the rectangle for the active slide */
    margin-right: 20px;
  }