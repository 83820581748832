.tinyConversationsSliderParent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    height: 340px;
    margin-left: 10px;
    background-color: black;
    border-radius: 8px;

}

.tinyConversationsSlide {
    width: 200px;
    height: 300px;
}