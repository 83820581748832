
.shippingComponentUpmostParent {
    width: 98%;
    margin-top: 10px;
}

.sellerAndShippingRowDiv {
    display: flex;
    height: 140px;
    width: 685px;
    padding: 5px;
    margin-left: 5px;
    margin-top: 7px;
    box-sizing: border-box;
    background-color: black;
    border-radius: 10px;
}

.shippingInfoParentDiv {
    display: flex;
    flex-direction: column;
    height: 130px;
    width: 322px;
    border-radius: 10px;
}

.shippingUnderline {
    width: 380px;
    height: 2px;
    background-color: white;
    margin-top: 5px;
    margin-left: 6px;
    margin-bottom: 15px;
}


.shippingInfoDeliveryDateDiv {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 200px;
    height: 40px;
    margin-top: 5px;
}

.deliveryInfoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    color: white;
    border-radius: 3px;
    cursor: pointer;
}

.deliveryInfoHoverDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: white;
    color: black; /* White text color */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 200px; /* Control the width */
    top: -90px; /* Control the position from the top, you can adjust this according to your needs */
    right: -87px; /* Position it at the right side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 1rem; /* Control the font size */
}

.shippingAlternativesDiv {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 3px;
}

.shippingCompaniesDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 150px;
    height: 30px;
    border-radius: 5px;
}

.learnAboutShippingLink {
    display: flex;
    margin-left: 14px;
    color: white;
    font-size: 16.5px;
}

.shippingText {
    margin-left: 7px;
}

@media (max-width: 1420px) {

    .sellerAndShippingRowDiv {
        width: 608px;
        height: 128px;

    }

}

@media (max-width: 1280px) {

    .sellerAndShippingRowDiv {
        width: 556px;

    }

}

@media (max-width: 1180px) {

    .sellerAndShippingRowDiv {
        flex-direction: column;
        height: 245px;
        width: 400px;
    }

    .shippingInfoParentDiv {
        height: 120px;
    }

}

@media only screen and (max-width: 960px) {

    .sellerAndShippingRowDiv {
        flex-direction: row;
        height: 140px;
        width: 685px;
    }

}

@media only screen and (max-width: 810px) {

    .sellerAndShippingRowDiv {
        width: 608px;
        height: 128px;

    }

}

@media only screen and (max-width: 720px) {

    .sellerAndShippingRowDiv {
        width: 556px;

    }

}

@media only screen and (max-width: 660px) {

    .shippingComponentUpmostParent {
        margin-top: 20px;
    }

    .sellerAndShippingRowDiv {
        flex-direction: column;
        height: 245px;
        width: 332px;
        margin-top: 35px;
    }

    .shippingInfoParentDiv {
        height: 120px;
    }

    .shippingUnderline {
        width: 90%;
    }

}
