.WatchKeyStatsSummaryParent {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.WatchKeyStatsSummaryStat {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
}

.WatchKeyStatsSummaryStatText {
    font-size: 1rem;
    font-weight: 400;
    margin: 0px;
    margin-left: 5px;
}