.animated-button {
    display: flex;
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s; /* This line added */

}

