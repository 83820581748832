.outerWatchBoxDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* This will align items towards the start of the container. */
    background-color: #cce6f4;
    width: 91%;
    height: 415px;
    margin: 25px 9px; 
    border-radius: 8px;
    box-sizing: border-box;

}

.watchBoxImageDiv {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center; /* Add this */

    width: 75%;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid black;

}

.watchBoxImageDiv:hover .modelImage {
    transform: scale(1.1);
}

.modelImage {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;

}

.watchBoxActionsDiv {
    display: flex;
    align-items: center;
    margin-top: 12px;
    width: 75%;
    height: 22%;
    background-color: white;
    border-radius: 8px;
    border: 2px solid black;
    overflow: visible;
}

.watchBoxTextDiv {
    width: 60%;
    height: 80%;
}

.watchBoxButtonsDiv {
    padding-top: 7px;
    padding-bottom: 7px;
    box-sizing: border-box;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30%;
    height: 100%;
    overflow: visible;
}

.watchBoxInfoButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 30px;
    background-color: #d00000;
    overflow: visible;
    border-radius: 5px;
}

.watchBoxButtonInfoText {
    position: absolute;
    bottom: 100%;
    left: -150%;
    transform: translateX(-50%);
    z-index: 2;
    color: white;
    background-color: black;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    min-width: 145px; /* limit the width of the info text */
    white-space: pre-wrap; /* Wraps the text and respects new lines */
    margin-bottom: 10px; /* add space below the text box */
    border: 2px solid white

}


.watchBoxSpan {
    font-weight: 550;
    color: white;
}





@media screen and (max-width: 600px) {
    .outerWatchBoxDiv {
        width: 80%;
        height: auto; /* consider changing to auto to accommodate smaller screens */
    }

    .watchBoxImageDiv {
        width: 100%;
        height: auto;
    }

    .watchBoxActionsDiv {
        align-items: ce;
        flex-direction: column; /* stack the elements vertically on smaller screens */
        height: auto;
    }

    .watchBoxTextDiv {
        width: 90%;
    }

    .watchBoxButtonsDiv {
        width: 100%;
        padding: 10px 0; /* add some vertical padding for smaller screens */
    }

    .watchBoxInfoButton {
        width: 70%;
    }

    /* adjust the tooltip to fit smaller screens */
    .watchBoxButtonInfoText {
        left: 0;
        transform: translateX(0);
        min-width: 0; 
        width: 100%; /* use full width for smaller screens */
    }
}