.watchDetailsDropDownContentParentDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 96%;
    border-radius: 8px;

}

.dropDownInputRow {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
}

.dropDownInputRowFirstItem {
    display: flex;
    align-items: center;
    width: 30%;
    height: 100%;
}

.dropDownCheckBoxDiv {
    
}

.frequencyFlexDiv {
    display: flex;
}

@media (max-width: 690px) {

    .dropDownInputRowFirstItem {
        width: 30%;
    }

}

@media (max-width: 570px) {

    .watchDetailsDropDownContentParentDiv {
        justify-content: flex-start;
    }

    .dropDownInputRow {
        flex-direction: column;
        height: 100px;
    }

    .dropDownInputRowFirstItem {
        width: 96%;
        height: 40%;
    }

}