



.watchImagesParentDiv {

        margin-top: 10px;
        display: grid;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        background-color: black;
        max-width: 720px;
        border-radius: 10px;

        grid-template-columns: repeat(4, 160px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 188px); /* Sets the height of each row based on content */
        grid-gap: 25px;
        border: 3px solid black;
        transition: width 0.2s, height 0.2s;
        overflow: hidden;
}

.watchImageBoxStyling {
    display: flex;
    flex-direction: column;
    width: 160px;
    height: 188px;
    box-sizing: border-box;
    cursor: grab; /* Add a grab cursor to indicate draggable */

    border: 0px solid black;
    border-radius: 6px;
    
}


.watchImageBoxStyling:active {
    cursor: grabbing; /* Change cursor to grabbing while dragging */
  }

.watchImageStyling {
    width: 160px;
    height: 160px;
    border-radius: 4px;
}

.mainPhotoIconDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 18px;
    position: absolute;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    box-sizing: border-box;
    top: -16px;
    right: 104px;
    background-color: black;
    border: 1px solid white;
}

.watchImageButtonRotateIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: -15px;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid black;
    color: white;
    cursor: pointer;

}

.watchImageButtonIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    
}

@media (max-width: 1300px) {

    .watchImagesParentDiv {

        max-width: 720px;
        grid-template-columns: repeat(4, 140px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 168px); /* Sets the height of each row based on content */
        grid-gap: 25px;

}

    .watchImageBoxStyling {
        width: 140px;
        height: 168px;
    }

    .watchImageStyling {
        width: 140px;
        height: 140px;
    }

    .mainPhotoIconDiv {
       left: -126px; 
    }

}

@media (max-width: 1240px) {

    .watchImagesParentDiv {

        max-width: 528px;
        grid-template-columns: repeat(4, 115px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 143px); /* Sets the height of each row based on content */
        grid-gap: 25px;
        border: 4px solid black;

}

    .watchImageBoxStyling {
        width: 115px;
        height: 143px;
    }

    .watchImageStyling {
        width: 115px;
        height: 115px;
    }

    .mainPhotoIconDiv {
       left: -101px; 
    }

}

@media (max-width: 1031px) {

    .watchImagesParentDiv {

        max-width: 720px;
        grid-template-columns: repeat(4, 160px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 188px); /* Sets the height of each row based on content */
        grid-gap: 25px;
        border: 4px solid black;

}

    .watchImageBoxStyling {
        width: 160px;
        height: 188px;
    }

    .watchImageStyling {
        width: 160px;
        height: 160px;
    }

    .mainPhotoIconDiv {
        left: -146px;
    }

}

@media (max-width: 900px) {

    .watchImagesParentDiv {

        max-width: 720px;
        grid-template-columns: repeat(4, 140px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 168px); /* Sets the height of each row based on content */
        grid-gap: 25px;

}

    .watchImageBoxStyling {
        width: 140px;
        height: 168px;
    }

    .watchImageStyling {
        width: 140px;
        height: 140px;
    }

    .mainPhotoIconDiv {
       left: -126px; 
    }

}

@media (max-width: 820px) {

    .watchImagesParentDiv {

        max-width: 720px;
        grid-template-columns: repeat(4, 115px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 143px); /* Sets the height of each row based on content */
        grid-gap: 25px;
        border: 4px solid black;

}

    .watchImageBoxStyling {
        width: 115px;
        height: 143px;
    }

    .watchImageStyling {
        width: 115px;
        height: 115px;
    }

    .mainPhotoIconDiv {
       left: -101px; 
    }

}

@media (max-width: 700px) {

    .watchImagesParentDiv {

        max-width: 400px;
        grid-template-columns: repeat(3, 115px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 143px); /* Sets the height of each row based on content */
        grid-gap: 25px;

}

}

@media (max-width: 570px) {

    .watchImagesParentDiv {

        max-width: 260px;
        grid-template-columns: repeat(2, 115px); /* Sets the width of each column */
        grid-template-rows: repeat(auto-fill, 143px); /* Sets the height of each row based on content */
        grid-gap: 25px;
        border: none;

}

}

