.sellerSingleTextReviewParentDiv {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    width: 100%;
    background-color: black;
    padding-bottom: 20px;
    border-radius: 10px;

    box-sizing: border-box;
}

.sellerSingleTextReviewTopRowDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 125px;
    border-top-right-radius: 10px;
}




.singleTextReviewUserComment {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 50px;
    justify-content: flex-end;
    box-sizing: border-box;
    margin-top: 50px;
    margin-left: 70px;
    font-size: 1.25rem;

}

.singleTextReviewSmallButtonDiv {
    display: flex;
    width: 100%;
    height: 70px;
    margin-top: 50px;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-right: 20px;
}

.sellerProfileUsefulReviewButton {
    display: flex;
    align-items: center;
    width: 180px;
    height: 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}


.fade-in {
    animation: fadeIn ease 0.6s;
    -webkit-animation: fadeIn ease 0.6s;
    -moz-animation: fadeIn ease 0.6s;
    -o-animation: fadeIn ease 0.6s;
    -ms-animation: fadeIn ease 0.6s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@media only screen and (max-width: 1425px) {

    .sellerSingleTextReviewTopRowDiv {
        height: 175px;
        align-items: normal;
        border: none;
    }

}


@media only screen and (max-width: 1325px) {

    .hide-below-1325 {
        display: none ;
      }


}


@media only screen and (max-width: 1230px) {
    .sellerSingleTextReviewTopRowDiv {
        height: 210px;
    }

    .singleTextReviewUserComment {
        margin-top: 0px;
    }

}


@media only screen and (max-width: 1100px) {
    .sellerSingleTextReviewTopRowDiv {
        height: 130px;
    }
}


@media only screen and (max-width: 975px) {
    .hide-below-975 {
        display: none;
    }

    .singleTextReviewUserComment {
        margin-left: 30px;
        font-size: 1.1rem;
    }
}



@media only screen and (max-width: 800px) {

    .sellerSingleTextReviewTopRowDiv {
        border-radius: 10px;
    }

    .singleTextReviewUserComment {
        margin-left: 30px;
        font-size: 1rem;
    }

    .singleTextReviewSmallButtonDiv {
        margin-top: 20px;
    }


    .hide-below-800 {
        display: none ;
      }



}

@media only screen and (max-width: 650px) {

    .sellerTextReviewsDropDownsParentDiv {
        flex-direction: column;
        height: 90px;
        background: none;
    }

    .sellerSingleTextReviewTopRowDiv {
        height: 100px;
        background-color: white;
    }

    .singleTextReviewUserComment {
        margin-top: 30px;
    }


}
