.descriptionComponentParentDiv {
    width: 98%;
    min-height: 60vh;
    box-sizing: border-box;
    background-color: black;
    border-radius: 10px;
    padding: 15px;
    white-space: pre-wrap;
    word-wrap: break-word;

}