
.BasicWatchInfoComponentParentDiv {
    display: flex;
    flex-direction: column;
    width: 98%;

    min-height: 600px;
    box-sizing: border-box;
    background-color: black;
    border-radius: 10px;
    padding: 30px;
    padding-right: 0px;

}

.partialInfoDiv {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    width: 90%;
    margin-left: 50px;
    margin-bottom: 30px;
    box-sizing: border-box;

}

.watchInfoRowDiv {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 57px;
    padding-top: 15px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.basicWatchInfoHeader {
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
}

.basicWatchInfoText {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
    margin-left: 15px;
}

.infoStatUnderLine {
    width: 95%;
    height: 0.5px;
    background-color: white;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
}


@media only screen and (max-width: 1300px) {

    .partialInfoDiv {
        width: 95%;
        margin-left: 25px;
    }

    .basicWatchInfoHeader {
        font-size: 1.2rem;
    }

    .basicWatchInfoText {
        font-size: 1.1rem;
    }

    .infoStatUnderLine {
        width: 90%;
    }

}

@media only screen and (max-width: 1100px) {

    .partialInfoDiv {
        width: 95%;
        margin-left: 15px;
    }

    .basicWatchInfoHeader {
        font-size: 1.1rem;
    }

    .basicWatchInfoText {
        font-size: 1rem;
    }

}

@media only screen and (max-width: 960px) {

    .BasicWatchInfoComponentParentDiv {
        width: 97%;
    }

    .partialInfoDiv {
        width: 86%;
        margin-left: 40px;
    }

    .infoStatUnderLine {
        width: 95%;
    }

    .basicWatchInfoHeader {
        font-size: 1.3rem;
    }

    .basicWatchInfoText {
        font-size: 1.2rem;
    }

}



@media only screen and (max-width: 550px) {

    .watchInfoRowDiv {
        min-height: 51px;
    }

    .partialInfoDiv {
        width: 86%;
        margin-left: 20px;
    }

    .infoStatUnderLine {
        width: 95%;
    }

    .basicWatchInfoHeader {
        font-size: 1.1rem;
    }

    .basicWatchInfoText {
        font-size: 1rem;
    }

}

@media only screen and (max-width: 400px) {
    .basicWatchInfoHeader {
        font-size: 1rem;
    }

    .basicWatchInfoText {
        font-size: 0.95rem;
    }
}