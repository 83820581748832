.simplifiedWatchListingLink {
    width: 310px;
    height: 405px;
    border-radius: 10px;
}

.simplifiedWatchListingParent {
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 410px;
    background-color: white;
    border-radius: 10px;
    padding: 19px;
    padding-bottom: 0px;
    box-sizing: border-box;
}

.simpleWatchListingSliderParentStyles {
    position: relative;
    overflow: hidden;
    width: 272px;
    height: 272px;
    border-radius: 8px;
}



.simpleWatchListingPreviewImage {
    border-radius: 8px;
    width: 272px;
    height: 272px;
    transition: transform 0.3s ease;
}

.simpleWatchListingPreviewImage:hover {
    transform: scale(1.1); /* Scale up the image on hover */
}

.simpleWatchListingText {
    margin-top: 6px;
    margin-left: 4px;
}

.simpleWatchListingSellerCommentDiv {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 6px;
    background-color: #dee2e6;
}

.simpleWatchListingInfoIconDiv {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-left: 2px;
}

.sellerInfoHoverDiv {
    position: absolute;
    z-index: 5; /* To ensure it's above all other content */
    background-color: rgba(0, 0, 0, 0.8); /* Black background with some transparency */
    color: white; /* White text color */
    padding: 10px; /* Add some padding */
    border-radius: 5px; /* Rounded corners */
    width: 230px; /* Control the width */
    top: -60px; /* Control the position from the top, you can adjust this according to your needs */
    right: 27px; /* Position it at the right side */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Optional: Add some shadow for 3D effect */
    line-height: 1.5; /* Space between lines of text */
    font-size: 0.9rem; /* Control the font size */
    text-align: justify; /* Justify the text */
} 

.simpleWatchListingPriceAndLocationDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding-top: 5px;
    box-sizing: border-box;
}

.simpleWatchListingPriceDiv {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100%;
}

.simpleWatchListingLocationDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 25px;
    height: 75%;
    margin-top: 7px;
}
