.personalInfomationUpmostParent {
    display: flex;
    width: 100%;
    height: 100%;
}

.personalInformationPageParentDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 860px;
    min-height: 1000px;
    padding: 30px;
    margin-left: 35px;
    margin-top: 30px;
    border: 3px solid #003251;
    background-color: white;
    border-radius: 8px;
}

.personalInformationSectionDiv {
    display: flex;
    width: 740px;
    flex-direction: column;
    min-height: 30vh;
    padding: 25px;
    margin-top: 30px;
    background-color: black;
    box-sizing: border-box;
    border: 3px solid black;
    border-radius: 8px;
}

.personalInformationSectionRow {
    display: flex;
    width: 97%;
    height: 70px;
    margin-top: 10px;
    align-items: center;
    
}

.personalInformationSectionRowFirstItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 22%;
    height: 100%;
}

.personalInformationSectionRowSecondItem {
    display: flex;
    align-items: center;
    width: 78%;
    height: 100%;
    padding-left: 20px;
}

@media only screen and (max-width: 1345px) {

    .personalInformationPageParentDiv {

        width: 750px;

    }

    .personalInformationSectionDiv {
        width: 680px;
    }

    .personalInformationSectionRow {
        width: 97%;
    }

    .personalInformationSectionRowFirstItem {
        width: 25%;
    }

    .personalInformationSectionRowSecondItem {
        width: 75%;
    }
    
}

@media only screen and (max-width: 1250px) {

    .personalInformationPageParentDiv {

        width: 700px;

    }

    .personalInformationSectionDiv {
        width: 640px;
    }

    .personalInformationSectionRowFirstItem {
        width: 25%;
    }

    .personalInformationSectionRowSecondItem {
        width: 75%;
    }
    
}

@media only screen and (max-width: 1165px) {

    .personalInformationPageParentDiv {

        width: 625px;
    }

    .personalInformationSectionDiv {
        width: 570px;
        padding: 18px;

    }

    .personalInformationSectionRowFirstItem {
        width: 25%;
    }

    .personalInformationSectionRowSecondItem {
        width: 75%;
    }
    
}

@media only screen and (max-width: 1032px) {

    .personalInformationPageParentDiv {
        width: 860px;
        margin-left: 0px;
        justify-self: center;
    }

    .personalInformationSectionDiv {
        width: 740px;
    }
    
}

@media only screen and (max-width: 900px) {

    .personalInfomationUpmostParent {
        justify-content: center;
    }

    .personalInformationPageParentDiv {
        width: 715px;
    }

    .personalInformationSectionDiv {
        width: 650px;
    }
    
}

@media only screen and (max-width: 780px) {

    .personalInfomationUpmostParent {
        justify-content: center;
    }

    .personalInformationPageParentDiv {
        width: 630px;
    }

    .personalInformationSectionDiv {
        width: 570px;
    }

    .personalInformationSectionRowFirstItem {
        width: 27%;
    }

    .personalInformationSectionRowSecondItem {
        width: 73%;
    }
    
}

@media only screen and (max-width: 680px) {

    .personalInfomationUpmostParent {
        justify-content: center;
    }

    .personalInformationPageParentDiv {
        width: 525px;
        padding: 0px;
        border: none;
    }

    .personalInformationSectionDiv {
        width: 100%;
    }
    
}

@media only screen and (max-width: 570px) {

    .personalInformationPageParentDiv {
        width: 425px;
        padding: 0px;
        border: none;
    }

    .personalInformationSectionRow {
        flex-direction: column;
        align-items: flex-start;
    }

    .personalInformationSectionRowFirstItem {
        width: 100%;
        justify-content: flex-start;
    }

    .personalInformationSectionRowSecondItem {
        width: 100%;
        padding-left: 0px;
    }
    
}

@media only screen and (max-width: 500px) {

    .personalInformationPageParentDiv {
        width: 336px;
        padding: 0px;
        border: none;
    }

}