.faqPageParent {
    justify-content: center;
    min-height: 100vh;
    padding-bottom: 30px;
    box-sizing: border-box;
    background-color: var(--silver-1);
}

.faqPageHeader {
    margin-bottom: 40px;
}

.faqPageContentDiv {
    display: flex;
    flex-direction: column;
    width: 700px;
    min-height: 100vh;
    padding-top: 50px;
    box-sizing: border-box;
}

.standardFaqDropdownContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
}

@media only screen and (max-width: 750px) {

    .faqPageContentDiv {
        width: 500px;
    }

}

@media only screen and (max-width: 540px) {

    .faqPageContentDiv {
        width: 350px;
    }

    .standardFaqDropdownContent {
        height: 260px;
    }

}