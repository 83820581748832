.buyWatchSummaryParent {
    display: flex;
    flex-direction: column;
    min-height: 900px;
    margin-top: 30px;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
}

.buyWatchSummaryPaymentDiv {
    display: flex;
    flex-direction: column;

}

.buyWatchSummaryPaymentInfoDiv {
    justify-content: space-between;
    height: 100px;
    margin-top: 25px;
}

.buyWatchSummarySmallHeader {
    font-size: 1.2rem;
    font-weight: 500;
}

.buyWatchSummarySmallText {
    font-size: 1.1rem;
    font-weight: 400;
    margin-left: 7px
}

.buyWatchSummaryWireTransferDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 160px;
    height: 30px;
    border-radius: 6px;
    background-color: black;
    padding: 7px;
}

.buyWatchSummaryOffer {
    display: flex;
    flex-direction: column;
    width: 520px;
    height: 270px;
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--gold-1);
    border-radius: 8px;
}

.buyWatchSummaryOfferRow {
    display: flex;
    justify-content: space-between;
    height: 35px;
}

.buyWatchSummarySmallHeaderThickness {
    font-weight: 400;
}

.buyWatchSummaryBuyButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 255px;
    height: 55px;
    background-color: white;
    border: 3px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for non-hover state */

}

.buyWatchSummaryBuyButton:hover {
    animation: pulsate 1s infinite; /* Apply the animation when hovered */
  }

  @media (max-width: 570px) {

    .buyWatchSummaryParent {
        background-color: var(--silver-2);
        width: 345px;
    }

    .buyWatchFormHeader {
        display: flex;
        justify-content: center;
    }

    .buyWatchSummaryPaymentInfoDiv {
        flex-direction: column;
    }

    .buyWatchSummaryWireTransferDiv {
        margin-top: 10px;
    }
    
    .buyWatchSummaryOffer {
        width: 305px;
        height: 315px;
        padding: 16px;

    }
    
    .buyWatchSummaryOfferRow {
        justify-content: space-between;
        height: 35px;
    }

    .buyWatchSummarySmallHeaderThickness {
        font-weight: 500;
    }
    

  }