.listingsOverViewParentDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 640px;
    margin-top: 20px;
    width: auto;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 30px;
    background-color: var(--blue-2);
    transition: height 0.2s ease;
}

.listingsOverViewParentDiv.expanded {

}

.listingsOverviewExpandButton {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -7px;
    left: 0;
    cursor: pointer;
    opacity: 1;
    border: none;
    background-color: #33415c;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: background-color 0.3s ease, z-index 0.3s ease;
}

.listingsOverviewExpandButton:hover {
    background-color: black;

    opacity: 1;
    z-index: 10;

}

.ListingsExpansionButtonText {
    opacity: 0.5;
}

.listingsOverviewExpandButton:hover .ListingsExpansionButtonText {
    opacity: 1;
}

.listingsOverviewHeader {
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    margin: 0px;
    margin-top: 25px;
    margin-left: 25px;
}

.listingsOverviewBoxDiv {
    
    min-height: 500px;
    padding: 20px;
    padding-top: 0px;
    box-sizing: border-box;
    border-radius: 10px;

}

.listingsOverviewRow {
    display: flex;
    flex-direction: row;
    gap: 25px;
    min-height: 500px;

    margin-top: 20px;
  }
  
  @media (max-width: 900px) {

    .listingsOverViewParentDiv {
        margin-left: auto;
        margin-right: auto;
    }

  }


  
    /* No change needed, the gap and wrapping will automatically adjust the layout */
  