.SellerDashBoardKeyStatisticsParent {
    position: relative;
    display: grid;  /* Change display to grid */
    grid-template-columns: 1fr 1fr;  /* Two columns each taking up half of the space */
    grid-template-rows: 1fr 1fr;  /* Two rows each taking up half of the space */
    gap: 0px;  /* Gap between grid items */
    width: 800px;
    height: 280px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    background-color: black;
    border-radius: 10px;
}

.SellerDashBoardKeyStatistic {
    display: flex;
    flex-direction: row;
    width: 340px;
    height: 90px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 6px;
}

.SellerDashBoardKeyStatisticIconParent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.SellerDashBoardKeyStatisticTextsParent {
    display: flex;
    flex-direction: column;
    height: 60px;
    width: 280px;
    padding-top: 4px;
    margin-left: 15px;
    box-sizing: border-box;


}

.SellerDashBoardKeyStatisticHeader {
    font-size: 1.4rem;
    color: white;
    margin: 0px;
}

.SellerDashBoardKeyStatisticText {
    font-size: 1.4rem;
    font-weight: 500;
    color: white;
    margin: 0px;
    margin-top: 4px;
}

@media only screen and (max-width: 900px) {

  .SellerDashBoardKeyStatisticsParent {
    width: 100%;
  }

  .SellerDashBoardKeyStatistic {
    width: 330px;
  }

}

@media only screen and (max-width: 800px) {

  .SellerDashBoardKeyStatisticHeader {
    font-size: 1.2rem;
    color: white;
    margin: 0px;
}

.SellerDashBoardKeyStatisticText {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    margin: 0px;
    margin-top: 4px;
}

.SellerDashBoardKeyStatistic {
  width: 290px;
}

}

@media only screen and (max-width: 720px) {

  .SellerDashBoardKeyStatisticHeader {
    font-size: 1.1rem;
    color: white;
    margin: 0px;
}

.SellerDashBoardKeyStatisticText {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    margin: 0px;
    margin-top: 4px;
}

.SellerDashBoardKeyStatistic {
  width: 280px;
}

}

@media only screen and (max-width: 650px) {
    .SellerDashBoardKeyStatisticsParent {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 400px;
        width: 360px;
    }

    .SellerDashBoardKeyStatistic {
      width: 340px;
    }

    .SellerDashBoardKeyStatisticHeader {
      font-size: 1.4rem;
      color: white;
      margin: 0px;
  }
  
  .SellerDashBoardKeyStatisticText {
      font-size: 1.4rem;
      font-weight: 500;
      color: white;
      margin: 0px;
      margin-top: 4px;
  }
}


@media only screen and (max-width: 550px) {
  .SellerDashBoardKeyStatisticsParent {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 400px;
      width: 309px;

      padding-left: 10px;
  }

  .SellerDashBoardKeyStatistic {
    width: 340px;
  }

  .SellerDashBoardKeyStatisticHeader {
    font-size: 1.25rem;
    margin: 0px;
}

.SellerDashBoardKeyStatisticText {
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    margin: 0px;
    margin-top: 4px;
}
}


  
  .bolt {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: var(--purple-1);
    border-radius: 50%;
  }
  
  .bolt::before,
  .bolt::after {
    content: "";
    position: absolute;
    background-color: black;
    width: 2px;
    height: 8px;  /* Adjusted height */
  }
  
  .bolt::before {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .bolt::after {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  .top-left {
    top: 5px;
    left: 5px;
  }
  
  .top-right {
    top: 5px;
    right: 5px;
  }
  
  .bottom-left {
    bottom: 5px;
    left: 5px;
  }
  
  .bottom-right {
    bottom: 5px;
    right: 5px;
  }
  
  
  
  