.brandPageParentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    padding-bottom: 60px;
    box-sizing: border-box;
}

.brandpageContentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    min-height: 80vh;
}

.watchBrandHeader {
    width: 100%;
    margin-top: 40px;
}



.headerDiv {
    margin-top: 20px;
}

.centerContainer {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensure the div takes up the full width */
}




.brandH1Styling {
    margin-left: 50px;
}

@media only screen and (max-width: 1410px) {

    .watchBrandHeader {
        width: 1050px
    }
}

@media only screen and (max-width: 1100px) {

    .watchBrandHeader {
        width: 830px
    }


}

@media only screen and (max-width: 860px) {

    .watchBrandHeader {
        width: 700px
    }


}

@media only screen and (max-width: 720px) {

    .watchBrandHeader {
        width: 550px
    }


}

@media only screen and (max-width: 630px) {
    .watchBrandHeader {
        font-size: 2.2rem;
        width: 98%
    }
}

@media only screen and (max-width: 560px) {

    .watchBrandHeader {
        width: 345px
    }


}