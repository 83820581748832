

.watchImageStyling2 {
    width: 160px;
    height: 160px;
    box-sizing: border-box;
    border: 1.5px solid black;
    border-radius: 6px;
}

.watchImageButtonRotateIcon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: -14px;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid black;
    color: white;
    cursor: pointer;

}

.watchImageButtonIconWrapper2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    
}