.singleTextReviewBoughtWatchDiv {
    display: flex;
    width: 830px;
    height: 125px;
    box-sizing: border-box;
    padding: 3px;
    background-color: white;
    border-bottom: 2px solid var(--gold-1);
    border-left: 2px solid var(--gold-2);

    border-top-left-radius: 0px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 0px;
    margin-top: 0px;
    margin-left: 0px;

}



.reviewBoughtWatchImage {
    width: 66px;
    height: 66px;
}

.singleTextReviewBoughtWatchModelAndStatsDiv {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 800px;
    margin-left: 10px;
    padding-top: 5px;

}

.singleTextReviewBoughtWatchKeyStatsDiv {
    display: flex;
    min-width: 750px;
    height: 40px;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-top: 5px;
    padding: 2px;
    padding-left: 0px;
    padding-right: 10px;
}

.group1PillsDiv {
    display: flex;
    width: 50%;
}

.group2PillsDiv {
    display: flex;
    width: 50%;
    margin-left: 15px;

}

.singleTextReviewWatchStatPill {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 20px;
    background: linear-gradient(to bottom right, var(--gold-1), var(--gold-2));

}




@media only screen and (max-width: 1400px) {

    .singleTextReviewBoughtWatchModelAndStatsDiv {
        display: flex;
        width: 600px;
        
    }

    .singleTextReviewBoughtWatchDiv {
        height: 185px;
        width: 580px;
        border-bottom-left-radius: 10px;

        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;

    }

    .boughtWatchDivImageAndHeaderAndKeyStatsDiv {
        display: flex;
        width: 560px;
        height: 110px;
    }

    .reviewBoughtWatchImage {
        width: 90px;
        height: 90px;
    }

    .boughtWatchDivHeaderAndKeyStatsDiv {
        width: 460px;
        height: 110px;
        margin-left: 10px;
    }

    .boughtWatchParagraph-break-line {
        max-width: 600px;
        white-space: nowrap;  /* Prevents text from wrapping to the next line */
        padding-left: 0px;
    }

    .singleTextReviewBoughtWatchKeyStatsDiv {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        margin-top: 6px;
        height: 78px;
    }

    .group1PillsDiv {
        display: flex;
        justify-content: space-between;
        width: 50%;
    }
    
    .group2PillsDiv {
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-left: 0px;
    
    }
}

@media only screen and (max-width: 1200px) {



    .reviewBoughtWatchImage {
        width: 66px;
        height: 66px;
    }

    .singleTextReviewBoughtWatchDiv {
        height: 185px;
        width: 480px;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;

    }

    .boughtWatchDivHeaderAndKeyStatsDiv {
        width: 380px;
    }



    .boughtWatchHoverDiv {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 450px;
        height: 35px;
        cursor: pointer;
        margin-top: 5px;
        border-radius: 18px;
        background-color: var(--silver-2);
    }


.boughtWatchPopupDiv {
    position: absolute;
    bottom: -0%; /* Position under the parent-div */
    left: 0;
    background: white;
    border: 1.5px solid var(--gold-2);
    opacity: 0;
    visibility: hidden;
    border-radius: 6px;
    padding: 10px;
    z-index: 1;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
  
  .boughtWatchHoverDiv:hover .boughtWatchPopupDiv {
    opacity: 1;
    visibility: visible;
}
}

@media only screen and (max-width: 1100px) {

    .singleTextReviewBoughtWatchDiv {
        width: 250px;
        height: 100px;
    }

    .reviewBoughtWatchImage {
        width: 90px;
        height: 90px;
    }

    .boughtWatchDetailsHoverDiv {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 35px;
        cursor: pointer;
        margin-top: 5px;
        margin-left: 8px;
        border-radius: 10px;
        background-color: var(--gold-2);
    }

    .boughtWatchShipmentHoverDiv {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 35px;
        cursor: pointer;
        margin-top: 5px;
        margin-left: 8px;
        border-radius: 10px;
        background-color: var(--gold-2);
    }

    .boughtWatchPopupDivAllInfo {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0%; /* Position under the parent-div */
        right: 0;
        width: 300px;
        height: 100px;
        background: white;

        border: 2px solid var(--gold-2);
        opacity: 0;
        visibility: hidden;
        border-radius: 6px;
        padding: 5px;
        z-index: 1;
        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    }
      
      .boughtWatchDetailsHoverDiv:hover .boughtWatchPopupDivAllInfo {
        opacity: 1;
        visibility: visible;
    }

    .watchDetailsPopUpDivKeyStat {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 25%;
    }
}

@media only screen and (max-width: 780px) {

    .singleTextReviewBoughtWatchDiv {
        align-items: center;
        width: 140px;
        height: 100px;
    }

    .reviewBoughtWatchImage {
        width: 80px;
        height: 80px;
    }

    .boughtWatchDetailsHoverDiv {
        height: 36px;
        width: 36px;
    }
}


@media only screen and (max-width: 650px) {

    .singleTextReviewBoughtWatchDiv {
        border: none;
    }

    .singleTextReviewBoughtWatchDiv {
        height: 100px;
        width: 60px;
    }

}



