.sellYourWatchPageParentDiv2 {
    display: flex;
    min-height: 1000px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
}


.sellYourWatchInputParentComponent2 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 780px;
    min-height: 2700px; /* Change from height to min-height */
    padding: 30px;
    border-radius: 8px;
    border: 4px solid #003251;
}

@media only screen and (max-width: 1160px) {
    
    .sellYourWatchInputParentComponent2 {
        width: 650px;

    }

}

@media only screen and (max-width: 1032px) {

    .sellYourWatchPageParentDiv2 {
        justify-content: center;
    }
    
    .sellYourWatchInputParentComponent2 {
        width: 780px;

    }

}

@media only screen and (max-width: 828px) {
    
    .sellYourWatchInputParentComponent2 {
        width: 650px;

    }

}

@media only screen and (max-width: 690px) {
    
    .sellYourWatchInputParentComponent2 {
        width: 590px;
        border: none;

    }

}

@media only screen and (max-width: 570px) {
    
    .sellYourWatchInputParentComponent2 {
        width: 380px;
        border: none;

    }

}






.sellYourWatchInputComponent2 {
    display: flex;


}

.sellYourWatchInputRowFirstItem2 {
    display: flex;

}

.sellYourWatchInputRowSecondItem2 {


}

