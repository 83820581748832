.dropDownParentDiv {
    display: flex;
    flex-direction: column;

}

.dropdownContent {
  display: flex;
  height: 0;
  align-items: center;
  overflow: hidden;
  transition: height 0.3s;


}

.dropdown {
    display: flex;
    align-items: center;
    height: 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 6px;
    border-bottom: 4px solid #d9d9d9;
}

.dropdownContentOpen {
    padding-top: 5px;
    box-sizing: border-box;
    background-image: url('../assets/SellWatchPageImages/coolBackgroundImage4.png'); /* Replace with the path to your image */
    background-size: cover; /* This ensures the image covers the entire div */
    background-repeat: no-repeat; /* Prevents image from repeating */
    background-position: center; /* Centers the image in the div */
    
  }
  
  .dropdownContentClosed {
    height: 0;
  }

.dropdown-closed {
  background-color: #d9d9d9;

}

.dropdown-open {
  background-color: #003251;


}

.dropdownTextClosed {
    color: black;
    transition: color 0.6s;

}

.dropdownTextOpen {
    color: white;
}

.arrowIcon {
    color: black;
    transform: rotate(0deg);
    transition: transform 0.3s, color 0.3s;

  }
  
  .rotateArrowIcon {
    color: white;
    transform: rotate(180deg);
    transition: transform 0.3s, color 0.3s;

  }