.listingPlacementText {
    margin: 0px;
    margin-top: 3px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--purple-2);
    transition: margin 0.3s ease;

}

.listingPlacementTextSmall {
    font-size: 1rem;
    margin: 0px;
    margin-top: 6px;
    font-weight: 600;
    color: var(--purple-2);

}