.numberOfImagesParentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    height: 160px;
    background-color: black;
    margin-top: 28px;
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    border: 3px dashed white;
}

@media (max-width: 1300px) {

    .numberOfImagesParentDiv {
        height: 140px;
        width: 140px;
    }
}

@media (max-width: 1240px) {

    .numberOfImagesParentDiv {
        height: 115px;
        width: 115px;
    }
}

@media (max-width: 1031px) {

    .numberOfImagesParentDiv {
        width: 160px;
        height: 160px;
    }

}

@media (max-width: 900px) {

    .numberOfImagesParentDiv {
        height: 140px;
        width: 140px;
    }
}

@media (max-width: 820px) {

    .numberOfImagesParentDiv {
        height: 115px;
        width: 115px;
    }
}