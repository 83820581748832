.personalInformationFieldParentDiv {
    display: flex;
    width: 500px;
    height: 40px;
    margin-top: 3px;
}

.personalInformationInputField {
    width: 100%;
    height: 42px;
    font-size: 1.15rem;
    font-weight: 500;
    padding-left: 8px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
}

.personalInformationInputField:focus {
    outline: none; /* Remove the default outline */
    background-color: var(--silver-1);
  }

  @media (max-width: 640px) {

    .personalInformationFieldParentDiv {
        display: flex;
        width: 400px;
        height: 40px;
        margin-top: 3px;
    }

  }

  @media (max-width: 500px) {

    .personalInformationFieldParentDiv {
        display: flex;
        width: 320px;
        height: 40px;
        margin-top: 3px;
    }

  }