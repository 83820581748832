.convosThumbnailsParent {
    display: flex;
    flex-direction: column;
    height: 642px;
    width: 340px;
    background-color: white;
    border: 1px solid black;
    border-bottom-left-radius: 10px;
    overflow-y: auto;  /* Vertical scrolling */


}