
.dropDownAndListingParentDiv {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    border-radius: 15px;
    background-color: #012a4a;
    padding: 20px;
    padding-bottom: 30px;
    margin-top: 70px;
    box-sizing: border-box;
}

.listingsHeader {
    font-size: 2.3rem;
    color: white;
    margin-bottom: 40px;
}



.dropDownParentDiv {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
}

.watchListingsParentDiv {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px; /* adjust as needed for space between grid items */
    width: 100%;
    box-sizing: border-box;
    height: auto;
}

.watchListingLink {
    text-decoration: none; /* removes underline */

}

@media only screen and (max-width: 1410px) {

    .dropDownAndListingParentDiv {
        width: 1050px
    }

    .watchListingsParentDiv {
        align-self: center;
        grid-template-columns: repeat(3, 1fr);
        width: 995px;
    }

}

@media only screen and (max-width: 1100px) {

    .dropDownAndListingParentDiv {
        width: 830px
    }

    .watchListingsParentDiv {
        align-self: center;
        grid-template-columns: repeat(3, 1fr);
        width: 780px;
    }

}

@media only screen and (max-width: 860px) {

    .dropDownAndListingParentDiv {
        width: 700px
    }

    .watchListingsParentDiv {
        align-self: center;
        grid-template-columns: repeat(2, 1fr);
        width: 646px;
    }
}

@media only screen and (max-width: 720px) {

    .dropDownAndListingParentDiv {
        width: 550px
    }

    .watchListingsParentDiv {
        align-self: center;
        width: 506px;
    }
}

@media only screen and (max-width: 630px) {
    .dropDownAndListingParentDiv {
        width: 98%
    }
}

@media only screen and (max-width: 570px) {

    .dropDownAndListingParentDiv {
        width: 345px
    }

    .watchListingsParentDiv {
        align-self: center;
        grid-template-columns: repeat(1, 1fr);
        width: 312px;
    }

    .listingsHeader {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 359px) {

    .dropDownAndListingParentDiv {
        width: 265px
    }

    .watchListingsParentDiv {
        align-self: center;
        grid-template-columns: repeat(1, 1fr);
        width: 240px;
    }

    .listingsHeader {
        font-size: 1.9rem;
    }
}