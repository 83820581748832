.hoverPopupDivParent {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: black;
}

.hoverPopupDivParent::after {
    content: '';
    position: absolute;
    right: 1px;
    bottom: -8px; /* Adjust this value to move the triangle up or down */
    width: 0;
    height: 0;
    border-left: 10px solid transparent; /* Adjust size */
    border-right: 10px solid transparent; /* Adjust size */
    border-top: 10px solid black; /* Adjust color and size; this is the color of the triangle */
}

@keyframes fadeInTextUpwards {
    from {
      opacity: 0;
      transform: translateY(10px); /* Start from 10px below */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.hoverPopupDivText {
    font-size: 0.9rem;
    font-weight: 400;
    color: white;
    animation: fadeInTextUpwards 0.5s ease forwards; /* Adjust the timing as you like */

}