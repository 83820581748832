
.watchInfoComponentParentDiv {
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: #012a4a;
    min-height: 700px;
    border-radius: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin-top: 10px;

}


.InfoComponentHeaderAndDescriptionAndSpecificationsParentDiv {
    display: flex;
    flex-direction: column;
    width: 93%;
    min-height: 300px;
    margin-top: 25px;
    padding-bottom: 15px;
    box-sizing: border-box;
    background-color: #0096c7;
    border-radius: 12px; 
}

.InfoComponentCommentAndSpecificationsParentDiv {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 40vh;
    margin-top: 15px;
    border-radius: 12px;
}


.InfoComponentCommentParentDiv {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom right, #0077b6, #023e8a);
    box-sizing: border-box;
    width: 93%;
    border-radius: 12px;
    padding: 10px;
    min-height: 100px;
}

.watchListingInfoSmallText {
    margin-top: 5px;
    color: white;
}



.InfoComponentPriceAndLogosAndButtonsParentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    min-height: 350px;
    margin-top: 23px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #023e8a;
    background: linear-gradient(to bottom right, #0077b6, #03045e);


}






.InfoComponentPaymentPriceAndLogosParentDiv {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin-top: 12px;
    height: 80px;
    border-radius: 10px;

}

.InfoComponentPriceParentDiv {
    
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    width: 124px;
}

.paymentOptionsDiv {
    display: flex;
    align-items: center;
    width: 232px;
}

.InfoComponentLogosParentDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 140px;
    margin-left: 10px;
    height: 35px;
    border-radius: 5px;
    background-color: white;
}

.InfoComponentBuyButtonsParentDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 95%;
    margin-top: 10px;
    height: 10vh;
    border-radius: 10px;

}

.InfoComponentBuyerProtectionParentDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    min-height: 170px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 15px;
    border-radius: 12px;
    background-color: white;
}



@media only screen and (max-width: 1050px) {
    .watchInfoComponentParentDiv {
    }
}

@media only screen and (max-width: 900px) {
    .watchInfoComponentParentDiv {
        width: 97%;
        background-color: white;
    }

    .InfoComponentBuyButtonsParentDiv {
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        margin-top: 30px;
        height: 100px;
        border-radius: 10px;
    
    } 

    .primeTickProtectionHeader {
        font-size: 1.2rem;
    }

    .primeTickProtectionText {
        font-size: 0.9rem;
    }

}

@media only screen and (max-width: 820px) {

    .primeTickProtectionHeader {
        font-size: 1.1rem;
    }

    .primeTickProtectionText {
        font-size: 0.85rem;
    }
}

@media only screen and (max-width: 725px) {



    .InfoComponentPaymentPriceAndLogosParentDiv {
        flex-direction: column;
        align-items: flex-start;
        height: 110px;
    }
}

@media only screen and (max-width: 500px) {

    .watchInfoComponentParentDiv {
        background-color: #012a4a;
    }
    
}
