.todaysNumbersParent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 540px;
    height: 100px;
    background-color: black;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 12px;
}

.todaysNumbersStat {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 230px;
    height: 50px;
    background-color: var(--purple-2);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px
}

.todaysNumbersStatHeader {
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
    margin: 0px;
}

.todaysNumbersStatText {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    margin: 0px;
    margin-left: 10px;
    margin-top: 2px;
}

@media (max-width: 900px) {

    .todaysNumbersParent {
        width: 100%;
    }

}

@media only screen and (max-width: 650px) {
    .todaysNumbersParent {
        flex-direction: column;
        height: 164px;
        width: 360px;
    }

    .todaysNumbersStat {
        width: 320px;
    }
}

@media only screen and (max-width: 550px) {
    .todaysNumbersParent {
        flex-direction: column;
        height: 164px;
        width: 312px;
    }

    .todaysNumbersStat {
        width: 280px;
    }
}