.tinyConversationOverviewParent {
    display: flex;
    flex-direction: column;
    width: 210px;
    min-height: 130px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
}

.conversationOverviewHeaderDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 7px;
    box-sizing: border-box;
    width: 210px;
    height: 30px;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #14213d;
}

.conversationOverviewHeaderText {
    font-size: 0.9rem;
    color: white;
    padding-left: 7px;
}

.conversationOverviewProfileInitialsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: var(--silver-1);
    border-radius: 11px;
}

.tinyConversationMainContentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    background-color: var(--silver-1);
    padding: 5px;

    padding-left: 8px;
    padding-bottom: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 9px;
}

.tinyConversationOverviewMessageDiv {
    width: 195px;
    min-height: 40px;
    margin-top: 3px;
    background-color: var(--silver-2);
    border-radius: 6px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: flex-start; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
}