.sliderArrow {
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
    background-color: white;
    opacity: 0.4;
    display: flex;
    align-items: center;
}

.sliderArrow.invisible {
    background-color: transparent;
    opacity: 0;
}

.sliderArrow:hover {
    animation: colorShift 0.6s forwards;
    background-color: var(--hover-color, #d00000);
    opacity: 1;
}

.sliderArrow.invisible:hover {
    opacity: 0.6;
}

.sliderArrow.no-hover:hover {
    background-color: transparent;
    opacity: 0.6;
}

.sliderArrowLeft {
    left: 15px; 
    transform: translateY(-50%);
}

.sliderArrowRight {
    right: 20px; 
    transform: translateY(-50%);
}

@keyframes colorShift {
    0% { color: initial; }
    20% { color: #ff002b; }
    40% { color: #ff7a00; }
    60% { color: #6e44ff; }    
    80% { color: #2b6eff; }
    100% { color: #00a6fb; }
}
