.listingPlacementText2 {
    margin: 0px;
    margin-top: 5px;
    font-size: 0.95rem;
    font-weight: 600;
    color: var(--purple-2);
    transition: margin 0.3s ease;

}

.listingPlacementTextSmall2 {
    font-size: 0.8rem;
    margin: 0px;
    font-weight: 600;
    color: var(--purple-2);

}