.SellerProfileReviewUserComponentParent {
    display: flex;
    align-items: center;
    height: 125px;
    width: 320px;
    padding-left: 15px;
    box-sizing: border-box;
    border-bottom-right-radius: 60px;
    border-right: 2px solid var(--gold-1);
    border-bottom: 2px solid var(--gold-2);

    background-color: white;
} 

.singleTextReviewProfilePicture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: var(--silver-1);
    border-radius: 45px;
}

.singleTextReviewProfileText {
    display: flex;
    flex-direction: column;
    height: 90px;
    box-sizing: border-box;
    margin-left: 10px;
    padding-left: 15px;
}



.sellerUserReviewText {
    font-size: 1.2rem;
}

.sellerUserReviewText2 {
    font-size: 1rem;
}

@media only screen and (max-width: 1200px) {

    .SellerProfileReviewUserComponentParent {
        height: 110px;
        width: 280px;
    }

    .singleTextReviewProfileText {
        padding-left: 5px;
    }

    .sellerUserReviewText {
        font-size: 1rem;
    }

    .sellerUserReviewText2 {
        font-size: 0.9rem;
    }

    .singleTextReviewProfilePicture {
        width: 80px;
        height: 80px;
    }
}

@media only screen and (max-width: 1100px) {


    .SellerProfileReviewUserComponentParent {
        height: 100px;
        width: 250px;
    }

    .singleTextReviewProfileText {
        height: 80px;
        padding-left: 5px;
    }

    .sellerUserReviewText {
        font-size: 0.9rem;
    }

    .sellerUserReviewText2 {
        font-size: 0.8rem;
    }

    .singleTextReviewProfilePicture {
        width: 65px;
        height: 65px;
    }
}

@media only screen and (max-width: 650px) {

    .SellerProfileReviewUserComponentParent {
        border: none;
    }
}