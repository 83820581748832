.sellPageParentDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}



.sellPageHeaderDiv {
    display: flex;
    width: 100%;
    height: 34px;
    background-color: black;
}

.sellPagePreviewHeader {
    font-size: 2rem;
    font-weight: 500;
    color: #003251;
    margin-left: 10px;
}

.sellPagePreviewText {
    font-size: 1rem;
    font-weight: 500;
    color: #003251;
    margin-left: 10px;
    margin-top: 20px;
}

.sellPageHeaderDivSectionDiv {
    display: flex;
    width: 26%;
    height: 100%;
    box-sizing: border-box;
}

.sellPageHeaderDivSectionRectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--blue-2);
}


.sellPageHeaderDivSectionTriangle {
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 34px solid var(--blue-2);
  }

.sellPageContentParentDiv {
    display: flex;
    width: 100%;
    min-height: 1000px;
    padding-bottom: 30px;
    box-sizing: border-box;

}

.sellPageSectionContentParentDiv {
    width: 66%;
    min-height: 1000px;
}

.sellPageListingPreviewParentDiv {
    display: flex;
    justify-content: center;
    width: 34%;
    min-height: 1000px;
}

.sellPageStickyPreviewParentComponent2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 30px;
    width: 400px;
    margin-top: 14px;
    height: 615px;
}

.sellPagePreviewParentComponent2 {
    
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 380px;
    height: 600px;
    padding: 20px;
    border-radius: 8px;
    border: 4px solid #003251;
}

.listingPreviewParentDiv2 {
    width: 318px;
    height: 418px;
    margin-top: 3px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 4px solid #003251;

}

@media only screen and (max-width: 1240px) {

    .sellPageSectionContentParentDiv {
        width: 70%;
    }

    .sellPageListingPreviewParentDiv {
        width: 30%;
    }

    .sellPageStickyPreviewParentComponent2 {
        width: 328px;
    }

    .sellPagePreviewParentComponent2 {
        width: 328px;
        border: none;
    }

    .sellPagePreviewHeader {
        margin-left: 0px;
    }

    .sellPagePreviewText {
        margin-left: 0px;
    }

}

@media only screen and (max-width: 1160px) {

    .sellPageSectionContentParentDiv {
        width: 66%;
    }

    .sellPageListingPreviewParentDiv {
        width: 42%;
    }



}

@media only screen and (max-width: 1032px) {

    .sellPageContentParentDiv {
        justify-content: center;
    }

    .sellPageSectionContentParentDiv {
        width: 800px;
    }

}
