.dynamicDropdown {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
  
  .dynamicDropdownToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    cursor: pointer;
    border: none;
    background: none;
    background-color: var(--silver-2);
  }

  .dynamicDropdownToggleText {
      font-size: 1.1rem;
      font-weight: 500;

  }

  .dynamicDropdownIconParent {
    position: relative;
    width: 25px;
    height: 25px;
  }

  .dynamicDropDownIcon {
    position: absolute;
    left: 0px;
    top: 0px;
    transition: opacity 0.2s ease-out;
  }

  .dynamicDropDownHideIcon {
    opacity: 0;
  }

  .dynamicDropDownShowIcon {
    opacity: 1;
  }
  
  .dynamicDropDownRotateIcon {
    transform: rotate(180deg);
}

.dynamicDropDownRotateIcon2 {
    transform: rotate(0deg);
}

  .dynamicDropDownShowIcon {
    opacity: 1;
  }

  .dynamicDropdownContent {
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Added from inline styles */
  }
  
 
  .dynamicDropdownContentOpen {
    animation: fadeInUpwards 0.5s ease forwards;
  }
  
  /* Optionally, a class for when the dropdown is closing */
  .dynamicDropdownContentClosed {
    animation: fadeOutDownwards 0.5s ease forwards;
  }
  
  @keyframes fadeInUpwards {
    from {
      opacity: 0;
      transform: translateY(10px); /* Start from 10px below */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Optional keyframes for the closing animation */
  @keyframes fadeOutDownwards {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-40px); /* End 10px down */
    }
  }
 



