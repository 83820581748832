.popularBrandsParentDiv {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 30px;
    width: 90%;    /* Takes up full width */
    height: 410px; /* Takes up at least the full viewport height */
    background-color: #051923;
    border-radius: 8px;
}

.slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 382px; /* Takes up at least the full viewport height */

}

.row {

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    padding-top: 10px;
    padding-left: 8px;
    padding-right: 8px;

}

.popularBrandsChildDiv {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;  /* Changed from 'center' to 'flex-start' */
    justify-content: center;
    padding: 15px; 
    border-radius: 12px;
    transition: transform 0.6s ease-in-out;
}

.brandImageDiv {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 115px;
    /* If you want to give some spacing between the image and the text, you can use margin-bottom */
    margin-bottom: 7px;
    overflow: hidden; /* Add this to clip the scaled-up image */
    border-radius: 10px; /* You can adjust this value */
}

.brandImage {
    /* Adjust width or height here, e.g.: */
    width: 90%;
    height: auto;
    border-radius: 10px;
    transform: scale(1.4);
    transition: transform 0.6s ease-in-out;
}



@media only screen and (max-width: 1400px) {

    .popularBrandsParentDiv {
        height: 425px;
        padding: 10px;
        width: 80%;
    }

    .row {
        /* Adjust grid layout for smaller screens */
        grid-template-columns: repeat(4, 1fr); /* Example: Change to a 2-column layout */
        grid-gap: 10px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-left: 4px;
        padding-right: 4px;
    }

}

@media only screen and (max-width: 1200px) {

    .popularBrandsParentDiv {
        height: 420px;
        padding: 10px;
        width: 86%;
    }

    .slide {
        height: 390px;
    }




}

@media only screen and (max-width: 1120px) {

    .popularBrandsParentDiv {
        height: 360px;
        width: 78%;
    }

    .slide {
        height: 90%;
        width: 800px;
    }

    .popularBrandsChildDiv {
        width: 185px;
        height: 120px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 95px;
    }

}

@media only screen and (max-width: 1120px) {

    .popularBrandsParentDiv {
        height: 330px;
        width: 880px;
    }

    .slide {
        height: 300px;
        width: 92%;
    }

    .popularBrandsChildDiv {
        width: 175px;
        height: 115px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 100px;
    }
}

@media only screen and (max-width: 900px) {

    .popularBrandsParentDiv {
        height: 380px;
        width: 86%;
    }

    .row {
        /* Adjust grid layout for smaller screens */
        grid-template-columns: repeat(3, 1fr); /* Example: Change to a 2-column layout */
        grid-gap: 10px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .slide {
        height: 340px;
        width: 92%;
    }

    .popularBrandsChildDiv {
        width: 195px;
        height: 135px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 100px;
    }
}

@media only screen and (max-width: 790px) {

    .popularBrandsParentDiv {
        height: 310px;
        width: 80%;
    }

    .slide {
        height: 280px;
        width: 92%;
    }

    .popularBrandsChildDiv {
        width: 145px;
        height: 100px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 95px;
    }
}

@media only screen and (max-width: 680px) {


    .popularBrandsParentDiv {
        height: 340px;
        width: 86%;
    }

    .slide {
        height: 320px;
        width: 92%;
    }

    .row {
        /* Adjust grid layout for smaller screens */
        grid-template-columns: repeat(2, 1fr); /* Example: Change to a 2-column layout */
        grid-gap: 10px;
        margin-bottom: 5px;
        padding-top: 5px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .popularBrandsChildDiv {
        width: 195px;
        height: 125px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 105px;
    }
}

@media only screen and (max-width: 540px) {
    .popularBrandsParentDiv {
        height: 270px;
        width: 75%;
    }

    .slide {
        height: 250px;
        width: 92%;
    }

    .popularBrandsChildDiv {
        width: 125px;
        height: 90px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 80px;
    }


}

@media only screen and (max-width: 480px) {
    .popularBrandsParentDiv {
        height: 270px;
        width: 85%;
    }

    .slide {
        height: 250px;
        width: 95%;
    }

    .popularBrandsChildDiv {
        width: 125px;
        height: 90px;
        padding: 10px;
    }

    .brandImageDiv {
        align-self: center;
        width: 95%;
        height: 80px;
    }


}


.popularBrandsParentDiv a {
    text-decoration: none;
    color: inherit;
}











@keyframes colorShift {
    0% { color: initial; }
    20% { color: #ff002b; }
    40% { color: #ff7a00; }
    60% { color: #6e44ff; }    
    80% { color: #2b6eff; }
    100% { color: #00a6fb; }
}

@keyframes underlineColorShift {
    0% { background: initial; }
    20% { background: #ff002b; }
    40% { background: #ff7a00; }
    60% { background: #6e44ff; }    
    80% { background: #2b6eff; }
    100% { background: #00a6fb; }
}



.popularBrandsChildDiv .spanStyle::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: #000000;
    transition: width 1.0s;
}








@media (hover: hover) and (pointer: fine) {
    .popularBrandsChildDiv:hover {
        transform: scale(1.05);
    }

    .popularBrandsChildDiv:hover .brandImageDiv .brandImage {
        transform: scale(1.6); /* Scale the image up */
    }

    .popularBrandsChildDiv:hover .spanStyle {
        animation: colorShift 0.6s forwards;
    }

    .popularBrandsChildDiv:hover .spanStyle::after {
        width: 100%;
        animation: underlineColorShift 0.6s forwards;
    }
}
