.securityPhotoComponentParentDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 360px;
    height: 200px;
    box-sizing: border-box;
    padding-right: 10px;
    border-radius: 6px;
    background-color: white;
}

.securityComponentImageStyling {
    width: 155px;
    height: 155px;

    border-radius: 6px;
}

@media only screen and (max-width: 1300px) {

    .securityPhotoComponentParentDiv {
        width: 320px;
        height: 170px;
    }

    .securityComponentImageStyling {
        width: 135px;
        height: 135px;

    }
    
}

@media only screen and (max-width: 1240px) {

    .securityPhotoComponentParentDiv {
        width: 270px;
        height: 150px;
    }

    .securityComponentImageStyling {
        width: 115px;
        height: 115px;

    }
    
}

@media only screen and (max-width: 1120px) {

    .securityPhotoComponentParentDiv {
        width: 246px;
        height: 150px;
    }

    .securityComponentImageStyling {
        width: 95px;
        height: 95px;

    }
    
}

@media only screen and (max-width: 1032px) {

    .securityPhotoComponentParentDiv {
        width: 360px;
        height: 200px;
    }

    .securityComponentImageStyling {
        width: 155px;
        height: 155px;
    }
    
}

@media only screen and (max-width: 900px) {

    .securityPhotoComponentParentDiv {
        width: 320px;
        height: 170px;
    }

    .securityComponentImageStyling {
        width: 135px;
        height: 135px;

    }
    
}

@media only screen and (max-width: 820px) {

    .securityPhotoComponentParentDiv {
        width: 270px;
        height: 150px;
    }

    .securityComponentImageStyling {
        width: 115px;
        height: 115px;

    }
    
}

@media only screen and (max-width: 700px) {

    .securityPhotoComponentParentDiv {
        width: 246px;
        height: 150px;
    }

    .securityComponentImageStyling {
        width: 95px;
        height: 95px;

    }
    
}