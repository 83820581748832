.mediumBlackButtonStyle {
    width: 450px;
    height: 42px;
    margin: 10px;
    margin-top: 30px;
    background-color: white;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
    border: 4px solid black;
    border-radius: 4px;
  }
  
  .mediumBlackButtonStyle:hover {
    cursor: pointer;
    background-color: black;
    color: white;
    transition: background-color 0.2s ease, color 0.2s ease; /* This line is the new one */

  }
  
  @media (max-width: 520px) {

    .mediumBlackButtonStyle {
      width: 354px;
    }

  }