.watchListingPage3ParentDiv {
    display: flex;
    flex-direction: column;
    min-height: 2000px;
    width: 100%;
    background-color: white;
}

.watchListingPageImagesAndInfoParentDiv {
    display: flex;
    background-color: white;
    width: 100%;
    min-height: 880px;
    box-sizing: border-box;
}

.watchListingPageDetailedDescriptionParentDiv {
    display: flex;
    margin-top: 20px;
    width: 100%;
    min-height: 500px;
    background-color: white;

}

.watchListingImagesSectionDiv {
    display: flex;
    justify-content: center;
    width: 60%;
    height: 893px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #012a4a;
    border-bottom-right-radius: 10px;
}

.watchListingInfoSectionDiv3 {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: 893px;
    box-sizing: border-box;
    background-color: white;
}

.watchListingPageImageSliderDiv {
    position: relative;
    height: 825px;
    width: 680px;
    border-radius: 8px;
    background-color: #012a4a;
}

.watchListingSliderImageStyling {
    height: 680px;
    width: 680px;
    border-radius: 10px;

}

.watchListingPageMultipleImagesParentDiv {
    display: flex;
    overflow-x: scroll; /* allows horizontal scrolling if there are too many images to fit */
    align-items: center; /* centers the images vertically */
    white-space: nowrap; /* prevents the images from wrapping to the next line */
    width: 100%;
    height: 120px;
    margin-top: 5px;
    scrollbar-width: thin; /* this is for Firefox */
    scrollbar-color: #888 transparent; /* this is for Firefox */
}

@media only screen and (max-width: 1300px) {

    .watchListingPageImageSliderDiv {
        height: 725px;
        width: 580px;
    }

    .watchListingSliderImageStyling {
        width: 580px;
        height: 580px;
    }


}

@media only screen and (max-width: 1050px) {

    .watchListingImagesSectionDiv {
        width: 52%;
    }

    .watchListingInfoSectionDiv3 {
        width: 48%;
    }


    .watchListingPageImageSliderDiv {
        height: 565px;
        width: 420px;
    }

    .watchListingSliderImageStyling {
        width: 420px;
        height: 420px;
    }


}

@media only screen and (max-width: 900px) {

    .watchListingImagesSectionDiv {
        width: 52%;
    }

    .watchListingInfoSectionDiv3 {
        width: 48%;
    }


    .watchListingPageImageSliderDiv {
        height: 505px;
        width: 360px;
    }

    .watchListingSliderImageStyling {
        width: 360px;
        height: 360px;
    }


}

@media only screen and (max-width: 795px) {

    .watchListingImagesSectionDiv {
        width: 48%;
        height: 750px;
    }

    .watchListingInfoSectionDiv3 {
        width: 52%;
    }

    .watchListingPageImageSliderDiv {
        height: 455px;
        width: 320px;
    }

    .watchListingSliderImageStyling {
        width: 320px;
        height: 320px;
    }

}

@media only screen and (max-width: 500px) {

    .watchListingPageImagesAndInfoParentDiv {
        flex-direction: column;
        align-items: center;
    }

    .watchListingImagesSectionDiv {
        width: 100%;
        height: 550px;
        border-radius: 0px;
    }

    .watchListingPageImageSliderDiv {
        height: 475px;
        width: 360px;
    }

    .watchListingSliderImageStyling {
        width: 360px;
        height: 360px;
    }

    .watchListingInfoSectionDiv3 {
        width: 100%;
        background-color: #012a4a;
    }

    .watchListingPageDetailedDescriptionParentDiv {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 380px) {
    .watchListingImagesSectionDiv {
        width: 100%;
        height: 500px;
        border-radius: 0px;
    }

    .watchListingPageImageSliderDiv {
        height: 425px;
        width: 300px;
    }

    .watchListingSliderImageStyling {
        width: 300px;
        height: 300px;
    }
}



.watchListingPageMultipleImagesParentDiv::-webkit-scrollbar {
    height: 8px; /* this is for Chrome, Safari, and Opera */
}

.watchListingPageMultipleImagesParentDiv::-webkit-scrollbar-track {
    background: rgba(208, 0, 0, 0.5);


}

.watchListingPageMultipleImagesParentDiv::-webkit-scrollbar-thumb {
    background: rgba(208, 0, 0, 0.5);


}

.watchListingPageMultipleImagesParentDiv::-webkit-scrollbar-thumb:hover {
    background: rgba(208, 0, 0, 0.5);

}

.watchListingPageMultipleImagesParentDiv::-webkit-scrollbar-thumb:active {
    background: #d00000; /* this is for Chrome, Safari, and Opera */
}

.watchListingPageSmallImage {
    width: 100px; /* adjust this to control the size of the images */
    height: auto; /* keep the original aspect ratio */
    display: inline-block;
    margin-right: 10px; /* adjust this to control spacing between images */
    cursor: pointer; /* indicates the images are clickable */
    border-radius: 4px; /* optional, for rounded corners */
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1); /* optional, for a bit of depth */
    opacity: 0.7; /* make the image semi-transparent */
}

.watchListingPageSmallImage.active {
    opacity: 1; /* fully opaque when active */
}

.watchListingPageMultipleImagesChildDiv {
    width: 60%;
    height: 80%;
    background-color: blue;
}








.watchListingHeaderAndCommentParentDiv {
    display: flex;
    flex-direction: column;
    width: 93%;
    margin-top: 20px;
    height: 20vh;
    background-color: #00b4d8;
    border-radius: 12px;

}

.watchListingHeaderAndCommentAndSpecificationsParentDiv3 {
    display: flex;
    flex-direction: column;
    width: 93%;
    margin-top: 20px;
    padding-top: 10px;
    height: 51vh;
    background-color: #0096c7;
    border-radius: 12px; 
}

.watchListingCommentAndSpecificationsParentDiv3 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 40vh;
    margin-top: 15px;
    border-radius: 12px;
}

.headerPlusCommentParentDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.watchListingCommentParentDiv3 {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: 93%;
    border-radius: 12px;
    padding: 10px;
    height: 21vh;


}



.priceAndLogosAndButtonsParentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    height: 51vh;
    margin-top: 23px;
    border-radius: 12px;
    background-color: #023e8a;


}




.paymentPriceAndLogosParentDiv {
    align-items: center;
    display: flex;
    width: 92%;
    margin-top: 12px;
    height: 10vh;
    border-radius: 10px;

}

.watchListingPriceParentDiv {
    
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    width: 30%;
}

.paymentLogosParentDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 40%;
    margin-left: 10px;
    height: 35px;
    border-radius: 5px;
    background-color: white;
}

.buyButtonsParentDiv {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin-top: 10px;
    height: 10vh;
    border-radius: 10px;

}

.buyerProtectionParentDiv {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 22vh;
    margin-top: 15px;
    border-radius: 12px;
    background-color: white;
}