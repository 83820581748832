.sellerDashboardKeyFiguresParent {
    position: relative;
    width: 840px;
    height: 1200px;
    background-color: #8ecae6;
    padding: 20px;
    margin-top: 30px;
    border-radius: 12px;
    box-sizing: border-box;
    align-self: center;
    justify-self: center;
}

.sellerDashboardSmallHeader {
    font-size: 1.4rem;
    color: black;
    margin: 0px;
    margin-bottom: 10px;
    font-weight: 400;
}

.personalInformationHeaderDiv {
    font-size: 1.4rem;
    font-weight: 400;
    color: black;
    margin: 0px;
}

.sellerDashboardKeyFiguresParentButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 230px;
    height: 100px;
    border-radius: 8px;
    top: 57px;
    right: 20px;
    cursor: pointer;
    border: 3px solid black;
    background: linear-gradient(to right, var(--purple-1) 50%, black 50%);
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: all 0.25s ease-out;
}

.sellerDashboardKeyFiguresParentButton:hover {
    background-position: 0 0;
  }

  @media only screen and (max-width: 900px) {

    .sellerDashboardKeyFiguresParent {
        width: 96%;
        height: fit-content;
        padding-bottom: 20px;
    }

    .sellerDashboardKeyFiguresParentButton {
        position: static ;
        height: 50px;
        width: 100%;
        margin-top: 20px;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
    }

  }

  @media only screen and (max-width: 800px) {

    .sellerDashboardKeyFiguresParent {
        width: 100%;
        height: fit-content;
        padding-bottom: 20px;
    }


  }

  @media only screen and (max-width: 650px) {
    .sellerDashboardKeyFiguresParent {
        width: 400px;
        height: 1695px;
        margin-left: auto;
        margin-right: auto;
    }

    .sellerDashboardKeyFiguresParentButton {
        position: static ;
        height: 50px;
        width: 100%;
        margin-top: 20px;
        flex-direction: row;
    }
}

@media only screen and (max-width: 550px) {
    .sellerDashboardKeyFiguresParent {
        width: 350px;
        height: 1695px;
        margin-left: auto;
        margin-right: auto;
    }

    .sellerDashboardKeyFiguresParentButton {
        position: static ;
        height: 50px;
        width: 100%;
        margin-top: 20px;
        flex-direction: row;
    }
}