.sellerDashboardParentDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300vh;
  box-sizing: border-box;
}

.slide-up-fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  /* final state of the div */
  .slide-up-fade-in.active {
    opacity: 1;
    transform: translateY(0);
  }