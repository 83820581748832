.conversationInputAreaParent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 55px;
    padding: 7px;
    width: 980px;
    background-color: #f8f9fa;
    box-sizing: border-box;
    border-bottom-right-radius: 10px;
}



.hiddenDiv {
    width: 960px;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    white-space: pre-wrap; 
    word-wrap: break-word;
    visibility: hidden;
    position: absolute;
}

.conversationTextInput {

    width: 960px;
    min-height: 44px;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    padding-right: 90px;
    padding-left: 20px;
    border: none;
    border-radius: 20px;
    box-sizing: border-box;
    border: 1px solid gray;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);  /* More pronounced shadow */

}

.conversationTextInput:focus {

    outline: none;
}

.conversationFileInput {
    position: absolute;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    cursor: pointer;

    opacity: 0;
    z-index: 3;  /* to ensure it's above the button */
}

.conversationImageButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 68px;
    width: 38px;
    height: 38px;
    background-color: black;
    cursor: pointer;
    border-radius: 19px;
    border: none;
    transition: background-color 0.3s ease;

}

.conversationImageButtonBGColorChange {
    background-color: var(--silver-2);
}

.conversationSendButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;
    background-color: black;
    cursor: pointer;
    border-radius: 19px;
    border: none;
    transition: background-color 0.3s ease;

}

.conversationButtonIconContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 19px;

}

@media only screen and (max-width: 1400px) {
    .conversationInputAreaParent {
        width: 700px;
    }

    .conversationTextInput {
        width: 680px;
    }
}
  
  .convoPlaneIcon, .convoCheckIcon {
    position: absolute;
    transition: opacity 0.3s ease-in-out;
  }
  
  .iconIn {
    opacity: 1;
    z-index: 2;
  }
  
  .iconOut {
    opacity: 0;
    z-index: 1;
  }
  

.conversationSendButton:hover {
    background-color: var(--silver-2);
}