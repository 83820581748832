
.learnAboutSellerLink {
    display: flex;
    color: black;
    font-size: 16.5px;
    width: 310px;
    height: 95px;
    margin-left: 30px;
    margin-top: 10px;
}

.shortSellerSummaryDiv {
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 95px;
    background-color: white;
    border-radius: 10px;

}

.shortSellerSummaryChildDiv {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;

}

.reviewScoreDiv {
    display: flex;
    align-items: center;
    width: 215px;
    margin-top: 7px;
    height: 36px;
    background-color: black;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;

}

.sellerReviewsLink {
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: white;
    font-size: 16.5px;
    text-decoration: none;
}



.sellerInfoText {
    margin-left: 7px;
}

@media (max-width: 1420px) {

    .shortSellerSummaryDiv {
        width: 255px;
    }

    .learnAboutSellerLink {
        width: 255px;
        margin-left: 10px;
    }

}

@media (max-width: 1280px) {

    .shortSellerSummaryDiv {
        width: 195px;
    }

    .learnAboutSellerLink {
        width: 195px;
        margin-left: 20px;
    }

    .reviewScoreDiv {
        width: 120px;
    }

}

@media (max-width: 1180px) {

    .learnAboutSellerLink {
        width: 310px;
        height: 95px;
        margin-top: 0px;
        margin-left: 15px;
    }

    .shortSellerSummaryDiv {
        width: 310px;
        height: 95px;
    }

    .reviewScoreDiv {
        width: 215px;
    }
    

}

@media only screen and (max-width: 960px) {

    .learnAboutSellerLink {
        margin-top: 10px;
    }

}

@media only screen and (max-width: 810px) {

    .shortSellerSummaryDiv {
        width: 255px;
    }

    .learnAboutSellerLink {
        width: 255px;
        margin-left: 10px;
    }

}

@media only screen and (max-width: 720px) {

    .shortSellerSummaryDiv {
        width: 195px;
    }

    .learnAboutSellerLink {
        width: 195px;
        margin-left: 20px;
    }

    .reviewScoreDiv {
        width: 120px;
    }

}

@media only screen and (max-width: 660px) {

    .learnAboutSellerLink {
        width: 250px;
        height: 95px;
    }
    
    .shortSellerSummaryDiv {
        width: 250px;
        height: 95px;
    }

}



