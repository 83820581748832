.searchBarParentDiv {
    display: flex;
    width: 800px;
    height: 43px;
}

.searchBarButtonIconWrapper {
    transition: all 0.3s ease;
  }
 
  .newSearchBarInput {
    padding-left: 15px;
  }

  .newSearchBarInput:focus {
    outline: none; /* Remove the default outline */
    border-bottom: 1px solid white;
  }

  @media (max-width: 900px) {
  
    .searchBarParentDiv {
        display: flex;
        width: 600px;
        height: 43px;
    }
}

@media (max-width: 700px) {

    .searchBarParentDiv {
        display: flex;
        width: 450px;
        height: 43px;
    }
}