.slide-up-fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  /* final state of the div */
  .slide-up-fade-in.active {
    opacity: 1;
    transform: translateY(0);
  }

  .paymentSectionParent {
    display: flex;
    flex-direction: column;
    min-height: 150vh;
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;

  }

  .paymentSectionSmallHeader {
    position: absolute;
    font-size: 1.5rem;
    color: white;
    margin: 0px;
    top: 20px;
    left: 25px;
    font-weight: 400;
  }

  .addPaymentCardButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background-color: white;
    border: 3px solid black;
    border-radius: 10px;
    font-size: 2rem;
    z-index: 5;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

.addPaymentCardButton:hover {
  color: white;
  background-color: #2dc653;
}

.addPaymentCardButtonPopup {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 130px;
  height: 30px;
  background-color: #e5e5e5;
  top: -25px;
  right: -57px;
}

.addPaymentCardButtonPopup::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust based on your needs */
  left: 20%; /* Center the triangle */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e5e5e5; /* Same color as the popup background */
  transform: translateX(-50%);
}

@media (max-width: 715px) {

  .paymentCardsParentDiv {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

}