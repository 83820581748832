.personalInformationInputSpace {
    display: flex;
    flex-direction: column;
    width: 430px;
    height: 400px;
    padding-left: 10px;
    box-sizing: border-box;
}

.personalInformationSmallText {
    font-size: 0.95rem;
    font-weight: 400;
    color: black;
    margin: 0px;
    margin-top: 20px;

}

.personalInformationSmallTextInputField{
    width: 160px;
    height: 40px;
    font-size: 1.1rem;
    margin-left: 20px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: none;
}

.personalInformationSmallTextInputField:focus {
    outline: none; /* Remove the default outline */
    background-color: var(--silver-1);
  }

  @media (max-width: 900px) {

    .personalInformationInputSpace {
        display: flex;
        flex-direction: column;
        width: 530px;
        height: 400px;
        padding-left: 10px;
        box-sizing: border-box;
    }

  }

  @media (max-width: 640px) {

    .personalInformationInputSpace {
        display: flex;
        flex-direction: column;
        width: 430px;
        height: 400px;
        padding-left: 0px;
        box-sizing: border-box;
    }

  }

  @media (max-width: 500px) {

    .personalInformationInputSpace {
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 400px;
        padding-left: 0px;
        box-sizing: border-box;
    }

  }